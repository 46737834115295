import React, { Component } from "react";
import "./adminPanel.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faDonate,
  faMoneyCheck,
  faSortAmountDownAlt,
  faTrophy
} from "@fortawesome/free-solid-svg-icons";
import { Link, Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { signOut } from "../actions/authAction";

// Components
import AddUser from "./AdminForm/AddUser";
import Users from "./FirebaseUsers/Users";
import TopDonators from "./AdminTopDonators/TopDonators";
import PaypalAdmin from "./AdminPaypal/Paypal";
import MonthWinner from "./AdminMonthWinner/MonthWinner";

class AdminPanel extends Component {
  render() {
    if (!this.props.firebase.auth.uid) return <Redirect to="/loginForm" />;
    return (
      <div className="adminPanel_wrapper">
        <div className="adminPanel_header">
          <div className="container">
            <div className="adminPanel_header_holder">
              <Link to="/">
                <h2 className="adminPanel_logo">Rich Only Club</h2>
              </Link>
              <div className="adminPanel_logout">
                <button onClick={this.props.signOut}>LOGOUT</button>
              </div>
            </div>
          </div>
        </div>
        <div className="adminPanel_holder">
          <div className="adminPanel_holder_wrap">
            <div className="adminPanel_menu">
              <ul>
                <li>
                  <Link to="/adminPanel">
                    <FontAwesomeIcon icon={faDonate} /> Donators
                  </Link>
                </li>
                <li>
                  <Link to="/adminPanel/paypal">
                    <FontAwesomeIcon icon={faMoneyCheck} />
                    Paypal
                  </Link>
                </li>
                <li>
                  <Link to="/adminPanel/addUser">
                    <FontAwesomeIcon icon={faUser} /> Add User
                  </Link>
                </li>
                <li>
                  <Link to="/adminPanel/topDonators">
                    <FontAwesomeIcon icon={faSortAmountDownAlt} /> Top Donators
                  </Link>
                </li>
                <li>
                  <Link to="/adminPanel/monthWinner">
                    <FontAwesomeIcon icon={faTrophy} /> Month Winner
                  </Link>
                </li>
              </ul>
            </div>
            {/* Route for admin panel */}
            <Switch>
              <Route exact path="/adminPanel" component={Users} />
              <Route path="/adminPanel/paypal" component={PaypalAdmin} />
              <Route path="/adminPanel/addUser" component={AddUser} />
              <Route path="/adminPanel/topDonators" component={TopDonators} />
              <Route path="/adminPanel/monthWinner" component={MonthWinner} />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
};

export default connect(mapStateToProps, { signOut })(AdminPanel);
