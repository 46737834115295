import React, { Component } from "react";
import "./addUser.css";
import { connect } from "react-redux";
import { createPendingDonator } from "../../actions/cronAction";
import { getCountries } from "../../config/countries";
import moment from "moment";

let allCountries = getCountries();

class AddUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Donator data
      username: "",
      email: "",
      password: "",
      message: "",
      country: "",
      donation: "",
      // Error messages
      usernameError: false,
      emailError: false,
      passwordError: false,
      messageError: false,
      countryError: false,
      donationError: false,
      timeError: false,
      // Admin time
      input_minutes: "",
      // Error time
      input_minutesError: false,
      successMsg: false
    };
  }

  getInputValue = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleSubmit = e => {
    e.preventDefault();

    if (
      !this.state.email ||
      !this.state.password ||
      !this.state.username ||
      !this.state.message ||
      !this.state.donation ||
      !this.state.country ||
      !this.state.input_minutes
    ) {
      if (!this.state.email) {
        this.setState({
          emailError: true
        });
      }
      if (!this.state.password || this.state.password.length < 6) {
        this.setState({
          passwordError: true
        });
      }
      if (!this.state.username || this.state.username.length < 4) {
        this.setState({
          usernameError: true
        });
      }
      if (!this.state.message) {
        this.setState({
          messageError: true
        });
      }
      if (!this.state.donation) {
        this.setState({
          donationError: true
        });
      }
      if (!this.state.country) {
        this.setState({
          countryError: true
        });
      }
      if (!this.state.input_minutes) {
        this.setState({
          input_minutesError: true
        });
      }
    } else {
      let newState = {
        email: this.state.email,
        password: this.state.password,
        username: this.state.username,
        donation: this.state.donation,
        message: this.state.message,
        country: this.state.country,
        date: moment()
          .add(this.state.input_minutes, "minutes")
          .format()
      };
      this.props.createPendingDonator(newState);
      this.setState({
        successMsg: true
      });
    }
  };

  handleClear = e => {
    this.setState({
      [e.target.id + "Error"]: false
    });
  };

  getFlagAndContry = e => {
    var elems = document.querySelectorAll(".active");
    [].forEach.call(elems, function(el) {
      el.classList.remove("active");
    });
    e.target.className = "active";
    if (e.target.className === "active") {
      this.setState({
        countryError: false
      });
    }
    let src = e.target.childNodes[0].src;
    let name = e.target.childNodes[1].data;
    let flag_country = [];
    flag_country.push(src, name);
    this.setState({
      country: flag_country
    });
  };

  render() {
    return (
      <div className="addUser_form">
        <h2>Create Donator Form</h2>
        <form onSubmit={this.handleSubmit} className="formForAddUser">
          <div className="addUser_wrapper">
            <div className="add_user_left">
              <div className="add_user_row">
                <label onClick={this.getAdminTime}>Email:</label>
                <input
                  type="email"
                  id="email"
                  onChange={this.getInputValue}
                  onFocus={this.handleClear}
                />
                {this.state.emailError ? (
                  <p className="validation_p">Please enter your email.</p>
                ) : null}
              </div>
              <div className="add_user_row">
                <label>Password:</label>
                <input
                  id="password"
                  onChange={this.getInputValue}
                  onFocus={this.handleClear}
                />
                {this.state.passwordError ? (
                  <p className="validation_p">
                    Password must be at least 5 characters.
                  </p>
                ) : null}
              </div>
              <div className="add_user_row">
                <label>Username:</label>
                <input
                  id="username"
                  onChange={this.getInputValue}
                  onFocus={this.handleClear}
                />
                {this.state.usernameError ? (
                  <p className="validation_p">
                    Username must be at least 3 characters.
                  </p>
                ) : null}
              </div>
              <div className="add_user_row">
                <label>Donation:</label>
                <input
                  type="number"
                  id="donation"
                  onChange={this.getInputValue}
                  onFocus={this.handleClear}
                />
                {this.state.donationError ? (
                  <p className="validation_p">Please enter your donation.</p>
                ) : null}
              </div>

              <div className="addUser_mini_form">
                <div className="add_user_row">
                  <label style={{ width: 200 }}>Delay minutes:</label>
                  <input
                    type="number"
                    id="input_minutes"
                    onChange={this.getInputValue}
                    onFocus={this.handleClear}
                  />
                  {this.state.input_minutesError ? (
                    <p className="validation_p">Please enter minute.</p>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="add_user_country">
              <div className="add_user_row">
                <label>Message:</label>
                <textarea
                  onFocus={this.handleClear}
                  id="message"
                  onChange={this.getInputValue}
                  placeholder="Your Message"
                ></textarea>
                {this.state.messageError ? (
                  <p className="validation_p">Please enter your message.</p>
                ) : null}
              </div>
              <label>Country:</label>
              <ul className="country_list">
                {allCountries.map((country, index) => (
                  <li key={index} onClick={this.getFlagAndContry}>
                    <img
                      alt="country_img"
                      className="flags"
                      src={
                        "http://flagpedia.net/data/flags/normal/" +
                        country.code.toLowerCase() +
                        ".png"
                      }
                    />
                    {country.name}
                  </li>
                ))}
              </ul>
              {this.state.countryError ? (
                <p className="validation_p">Please select your country.</p>
              ) : null}
            </div>
          </div>
          <div className="addUser_btn_submit">
            <button>Create donator</button>
          </div>
          {this.state.successMsg ? (
            <p className="successCreateDonator">Success create donator</p>
          ) : null}
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
};

export default connect(mapStateToProps, { createPendingDonator })(AddUser);
