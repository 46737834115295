import React, { Component } from "react";
import "./paypal.css";
import { connect } from "react-redux";
import { setUsers } from "../../actions/action";
import { db } from "../../config/fbConfig";
import cancel_icon from "../../img/cancel_icon.png";
import ClientID from "./ClientID";

class PaypalAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      secret_id: "",
      visiblePopup: false,
      dataId: ""
    };
  }

  componentDidMount() {
    this.getPaypalId();
  }

  getPaypalId = () => {
    db.collection("paypal-ID")
      .get()
      .then(snapshot => {
        snapshot.docs.map(item =>
          this.setState({
            secret_id: item.data().secret_id
          })
        );
        let IDs = "";
        snapshot.docs.map(doc => {
          IDs = doc.id;
          return doc.data();
        });
        this.setState({
          dataId: IDs
        });
      });
  };

  editIdPopup = () => {
    this.setState({
      visiblePopup: !this.state.visiblePopup
    });
  };

  handleChange = e => {
    this.setState({
      secret_id: e.target.value
    });
  };

  updateSecretId = () => {
    db.collection("paypal-ID")
      .doc(this.state.dataId)
      .update({
        secret_id: this.state.secret_id
      });
    this.setState({
      visiblePopup: false
    });
  };

  closePopup = () => {
    this.setState({
      visiblePopup: false
    });
  };

  render() {
    const { secret_id } = this.state;
    return (
      <div className="adminPanel_content">
        <div className="adminPanel_palypal">
          <h2>Paypal</h2>
          <div className="adminPanel_paypal_table">
            <ClientID />
            <div className="adminPanel_paypal_table_body">
              <h2>Secret ID:</h2>
              <p>{secret_id}</p>
              <button onClick={this.editIdPopup}>Edit</button>
            </div>
          </div>
          {this.state.visiblePopup ? (
            <div className="paypalPopup">
              <div className="paypalPopup_holder">
                <img
                  src={cancel_icon}
                  className="paypalPopup_close"
                  onClick={this.closePopup}
                />
                <input
                  onChange={this.handleChange}
                  type="text"
                  placeholder="Edit Id"
                />
                <button onClick={this.updateSecretId}>Submit</button>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
};

export default connect(mapStateToProps, { setUsers })(PaypalAdmin);
