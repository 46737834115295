import React, { Component } from "react";
import "./topLists.css";
import moment from "moment";
import EnvelopePopUp from "./EnvelopePopUp/EnvelopePopUp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { db } from "../../config/fbConfig";
import {
  setStatusEnvelope,
  setVisibleTopLists,
  setUsers,
  setTodayUsers,
  setMonthUsers
} from "../../actions/action";
import CloseIcon from "../../img/cancel_icon.png";

const languages = {
  en: require("../../languages/en.json"),
  fr: require("../../languages/fr.json"),
  es: require("../../languages/es.json"),
  ru: require("../../languages/ru.json"),
  cn: require("../../languages/cn.json")
};

// Get current day and current mounth
const todayDate = moment()
  .format()
  .substring(0, 10);
const month = moment()
  .format()
  .substring(0, 7);

class TopLists extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listActive: "1",
      userMessage: "",
      userNick: ""
    };
  }

  componentDidMount() {
    // Call func get all users for firebase
    this.getUsersFromFireBase();
  }

  getUsersFromFireBase = () => {
    // Get all users for firebase
    db.collection("donators").onSnapshot(querySnapshot => {
      let IDs = [];
      let data = querySnapshot.docs.map(doc => {
        IDs.push(doc.id);
        return doc.data();
      });

      let dataWithID = data.map((item, index) => {
        item.id = IDs[index];

        return item;
      });

      // set all users
      this.props.setUsers(dataWithID);

      // set today users
      const currentDay = this.props.getUsers.filter(
        user => user.date.substring(0, 10) === todayDate
      );
      this.props.setTodayUsers(currentDay);

      // set month users
      const currentMonth = this.props.getUsers.filter(
        user => user.date.substring(0, 7) === month
      );
      this.props.setMonthUsers(currentMonth);
    });
  };

  getListItem = user => {
    this.setState({
      userNick: user.username,
      userMessage: user.message
    });
    if (this.props.statusEnvelope === false) {
      this.props.setStatusEnvelope(true);
    } else {
      this.props.setStatusEnvelope(false);
    }
  };

  closeTopList = () => {
    // Close top list component
    this.props.setVisibleTopLists(false);
    this.props.setStatusEnvelope(false);
  };

  changeList = e => {
    // Set active list and active button
    this.setState({
      listActive: [e.target.id].toString()
    });
  };

  render() {
    const { getUsers, getTodayUsers, getMonthUsers } = this.props;

    const { listActive } = this.state;

    let local = localStorage.getItem("activeLang");

    return (
      <div className="topLists_wrapper">
        {languages[local].top_list_page.map((item, index) => (
          <div className="topLists_wrap_holder" key={index}>
            <img
              src={CloseIcon}
              alt="CloseIcon"
              onClick={this.closeTopList}
              className="closeTopListPopupImg"
            />
            <div className="topLists_buttons">
              <button
                id="1"
                onClick={this.changeList}
                className={listActive === "1" ? "active_list_btn" : ""}
              >
                {item.topToday}
              </button>
              <button
                id="2"
                onClick={this.changeList}
                className={listActive === "2" ? "active_list_btn" : ""}
              >
                {item.topMonth}
              </button>
              <button
                id="3"
                onClick={this.changeList}
                className={listActive === "3" ? "active_list_btn" : ""}
              >
                {item.topOfAllTime}
              </button>
            </div>
            <div className="topLists">
              <div
                className="topList_today topList"
                style={
                  listActive === "1"
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <h2>{item.topToday}</h2>
                <ul
                  className="topToday topList_ul"
                >
                  {getTodayUsers.length ? (
                    getTodayUsers
                      .sort(
                        (a, b) =>
                          parseFloat(b.donation) - parseFloat(a.donation)
                      )
                      .slice(0, 10)
                      .map((user, index) => {
                        return (
                          <div className="topList_row" key={user.id}>
                            <div className="social_icon">
                              <span>{`#${index + 1}`}</span>
                            </div>
                            <div className="topList_row_content_username">
                              {user.username}
                            </div>
                            <div className="topList_row_content_donation">{`$${user.donation}`}</div>
                            <div className="topList_row_content_message">
                              <FontAwesomeIcon
                                icon={faEnvelope}
                                onClick={() => this.getListItem(user)}
                              />
                            </div>
                            <div className="topList_country_img_holder">
                              <img
                                className="topList_country_img"
                                src={user.country[0]}
                                title={user.country[1]}
                              />
                            </div>
                          </div>
                        );
                      })
                  ) : (
                    <p className="nohaveDonation">{item.noDonation}</p>
                  )}
                </ul>
                {this.props.statusEnvelope ? (
                  <EnvelopePopUp
                    message={this.state.userMessage}
                    username={this.state.userNick}
                  />
                ) : null}
              </div>
              <div
                className="topList_Mount topList"
                style={
                  listActive === "2"
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <h2>{item.topMonth}</h2>
                <ul
                  className="topMount topList_ul"
                >
                  {getMonthUsers.length ? (
                    getMonthUsers
                      .sort(
                        (a, b) =>
                          parseFloat(b.donation) - parseFloat(a.donation)
                      )
                      .slice(0, 10)
                      .map((user, index) => {
                        return (
                          <div className="topList_row" key={user.id}>
                            <div className="social_icon">
                              <span>{`#${index + 1}`}</span>
                            </div>
                            <div className="topList_row_content_username">
                              {user.username}
                            </div>
                            <div className="topList_row_content_donation">{`$${user.donation}`}</div>
                            <div className="topList_row_content_message">
                              <FontAwesomeIcon
                                icon={faEnvelope}
                                onClick={() => this.getListItem(user)}
                              />
                            </div>
                            <div className="topList_country_img_holder">
                              <img
                                className="topList_country_img"
                                src={user.country[0]}
                                title={user.country[1]}
                              />
                            </div>
                          </div>
                        );
                      })
                  ) : (
                    <p className="nohaveDonation">{item.noDonation}</p>
                  )}
                </ul>
                {this.props.statusEnvelope ? (
                  <EnvelopePopUp
                    message={this.state.userMessage}
                    username={this.state.userNick}
                  />
                ) : null}
              </div>
              <div
                className="topList_topAllTime topList"
                style={
                  listActive === "3"
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <h2>{item.topOfAllTime}</h2>
                <ul
                  className="topAllTime topList_ul"
                >
                  {getUsers.length ? (
                    getUsers
                      .sort(
                        (a, b) =>
                          parseFloat(b.donation) - parseFloat(a.donation)
                      )
                      .slice(0, 10)
                      .map((user, index) => {
                        return (
                          <div className="topList_row" key={user.id}>
                            <div className="social_icon">
                              <span>{`#${index + 1}`}</span>
                            </div>
                            <div className="topList_row_content_username">
                              {user.username}
                            </div>
                            <div className="topList_row_content_donation">{`$${user.donation}`}</div>
                            <div className="topList_row_content_message">
                              <FontAwesomeIcon
                                icon={faEnvelope}
                                onClick={() => this.getListItem(user)}
                              />
                            </div>
                            <div className="topList_country_img_holder">
                              <img
                                className="topList_country_img"
                                src={user.country[0]}
                                title={user.country[1]}
                              />
                            </div>
                          </div>
                        );
                      })
                  ) : (
                    <p className="nohaveDonation">{item.noDon}</p>
                  )}
                </ul>
                {this.props.statusEnvelope ? (
                  <EnvelopePopUp
                    message={this.state.userMessage}
                    username={this.state.userNick}
                  />
                ) : null}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
};

export default connect(mapStateToProps, {
  setStatusEnvelope,
  setVisibleTopLists,
  setUsers,
  setTodayUsers,
  setMonthUsers
})(TopLists);
