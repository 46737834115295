import React, { Component } from "react";
import "./envelopePopUp.css";
import { connect } from "react-redux";
import { setStatusEnvelope } from "../../../actions/action";
import CloseIcon from "../../../img/cancel_icon.png";

class EvelopePopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  closeMessage = () => {
    // Close envelope popup
    this.props.setStatusEnvelope(false);
  };

  render() {
    return (
      <div className="evelope_wrapper">
        <img
          src={CloseIcon}
          alt="CloseIcon"
          onClick={this.closeMessage}
          className="closeEvelopePopup"
        />
        <div className="evelope_holder">
          <h3>{this.props.username}</h3>
          {this.props.message}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
};

export default connect(mapStateToProps, { setStatusEnvelope })(EvelopePopUp);
