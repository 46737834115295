import React, { Component } from "react";
import "../adminPanel.css";
import Edit from "../AdminEditForm/Edit";
import { connect } from "react-redux";
import {
  setUsers,
  getRealTimeUsers,
  setEditPopUpStatus,
  setPendingUsers
} from "../../actions/action";
import { createDonator } from "../../actions/formAction";
import { db } from "../../config/fbConfig";
import Pagination from "../pagination/Pagination";
import { paginate } from "../utils/paginate";
import moment from "moment";

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editPopUp_status: false,
      users: [],
      updatedUser: [],
      userForEdit: [],
      //Pagination variables
      pageSize: 20,
      currentPage: 1,
      currentElement: 0
    };
  }

  componentWillUnmount() {
    this.props.setEditPopUpStatus(false);
  }

  componentDidMount() {
    this.getUsersFromFireBase();
    // this.getPendingDonators();
    // setInterval(() => {
    //   this.getUsersFromFireBase();
    //   this.getPendingDonators();
    //   let a = this.props.getUsers;
    //   let b = this.props.getPendingUsers;
    //   let date = moment().format();
    //   if (b != null) {
    //     b.map(user => {
    //       if (
    //         a.map(d => d.email.toString()) !== user.email.toString() &&
    //         user.date <= date
    //       ) {
    //         console.log("PROSLO");
    //         this.props.createDonator(user);
    //         this.deletePendingList(user);
    //       } else {
    //         console.log("NIJE");
    //       }
    //     });
    //   }
    // }, 5000);
    // clearInterval();
  }

  getUsersFromFireBase = () => {
    db.collection("donators")
      .orderBy("date", "desc")
      .get()
      .then(querySnapshot => {
        let IDs = [];
        let data = querySnapshot.docs.map(doc => {
          IDs.push(doc.id);
          return doc.data();
        });

        let dataWithID = data.map((item, index) => {
          item.id = IDs[index];

          return item;
        });
        // console.log(dataWithID);

        this.props.setUsers(dataWithID);
        this.setState({
          users: dataWithID
        });
      });
  };

  // getPendingDonators = () => {
  //   db.collection("pending-donators")
  //     .orderBy("date", "desc")
  //     .get()
  //     .then(querySnapshot => {
  //       let IDs = [];
  //       let data = querySnapshot.docs.map(doc => {
  //         IDs.push(doc.id);
  //         return doc.data();
  //       });

  //       let dataWithID = data.map((item, index) => {
  //         item.id = IDs[index];

  //         return item;
  //       });
  //       console.log(dataWithID);

  //       this.props.setPendingUsers(dataWithID);
  //     });
  // };

  deleteList = users => {
    let id = users.id;
    db.collection("donators")
      .doc(id)
      .delete();
    let newUsers = this.state.users.filter(user => user.id !== users.id);
    this.setState({
      users: newUsers
    });
  };

  // deletePendingList = users => {
  //   let id = users.id;
  //   db.collection("pending-donators")
  //     .doc(id)
  //     .delete();
  // };

  editList = user => {
    this.setState({
      updatedUser: user
    });
    console.log(user, "USERRRRR");
    if (this.props.editPopUpStatus === false) {
      this.props.setEditPopUpStatus(true);
    } else {
      this.props.setEditPopUpStatus(false);
    }
  };

  grabStateFromChild = userForEdit => {
    let newUsers = [];
    this.state.users.map(user => {
      if (user.id === userForEdit.id) {
        user = userForEdit;
      }
      return newUsers.push(user);
    });

    this.setState({
      users: newUsers
    });
  };

  handlePageChange = page => {
    this.setState({
      currentPage: page
    });
  };

  render() {
    const { users, pageSize, currentPage } = this.state;

    const donators = paginate(users, currentPage, pageSize);

    return (
      <div className="adminPanel_content">
        <div className="adminPanel_search">
          <p>
            Number of donators: <span>{users.length}</span>
          </p>
        </div>
        <div className="adminPanel_content_table">
          <div className="adminPanel_content_table_header">
            <ul>
              <li>Username</li>
              <li>Password</li>
              <li>Email</li>
              <li>Message</li>
              <li>Country</li>
              <li>Donation</li>
              <li>Date</li>
              <li>Edit</li>
              <li>Delete</li>
            </ul>
          </div>
          <div className="adminPanel_content_table_body">
            {donators.map(user => (
              <ul key={user.id} data-id={user.id}>
                <li>{user.username}</li>
                <li>{user.password}</li>
                <li>{user.email}</li>
                <li>{user.message}</li>
                <li>
                  <img
                    src={user.country[0]}
                    alt="country"
                    title={user.country[1]}
                  />
                </li>
                <li>{user.donation}</li>
                <li>
                  {/* {user.date} */}
                  {/* 2019-09-12T11:43:16+02:00 */}
                  Date: {user.date.substring(0, 10)}
                  <br />
                  Time: {user.date.substring(11, 19)}
                </li>
                <li>
                  <button className="edit" onClick={() => this.editList(user)}>
                    Edit
                  </button>
                </li>
                <li>
                  <button
                    className="delete"
                    onClick={() => this.deleteList(user)}
                  >
                    Delete
                  </button>
                </li>
              </ul>
            ))}
            {this.props.editPopUpStatus ? (
              <Edit
                user={this.state.updatedUser}
                grabStateFromChild={this.grabStateFromChild}
              />
            ) : null}
          </div>
        </div>
        <Pagination
          itemsDonators={users.length}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageChange={this.handlePageChange}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
};

export default connect(mapStateToProps, {
  setUsers,
  getRealTimeUsers,
  setEditPopUpStatus,
  setPendingUsers,
  createDonator
})(Users);
