import React from "react";
import "./termsPage.css";
const TermsPage = () => {
  return (
    <div className="termsPage">
      <div className="container">
        <h2>Terms of Use & Privacy Policy</h2>
        <div className="termsPage_content">
          <p>
            Before using our page we kindly ask you to read our Terms &
            Conditions. When registering an account on the Website of
            MagicUnbox.com, you (the "User") accept and agree to abide by these
            terms and conditions so for the the documents referred to therein.
            If you do not accept these terms and conditions, you should not use
            the Website or the Services. Use of Service conditions If you want
            to use the website MagicUnbox.com, you should be over eighteen years
            of age (18). In order to access the website, you need a desktop or
            mobile browser. If you have any troubles while opening webpage or
            using our services, please download a supported Web browser or
            update it to latest version. You agree and accept the fact that the
            performance of the Service is dependent on the performance of device
            you are using and quality of your Internet connection. You agree to
            sign up and register at MagicUnbox.com Services through your
            Facebook, Twitter, Instagram or Google+ accounts. It is you own
            responsiblity to manage your account and password so is for keeping
            your password confidential. You also agree to be solely responsible
            for restricting access to your account. You agree that you are
            responsible for all activities that occur on your account or through
            the use of your password by yourself or by other persons using your
            account. If you believe that a third person has access your
            password, You can use the password regeneration feature of the
            Service as soon as possible to obtain and generate a new password.
            You agree not to permit any third party to use or access the website
            in any circumstances. You also agree that actual product you may
            receive can be slightly different from shown image as a result of
            the availability on the market.
          </p>
          <p>
            <b>
              To use our services, you agree with the following statements: ·
            </b>
            You may not impersonate or misrepresent any person or entity you are
            associated with. ; · You are not to access the page in order
            interfere or to cause damage and neither to make unauthorized
            alterations. You agree not to use any non-public areas of the
            Service or MagicUnbox.com computer systems; · In any
            circumstances,You may not attempt to probe, scan, or test the
            vulnerability of the page’s Service or any related systems or
            networks related to MagicUnbox.com. You may not attempt to breach
            any security or authentication measures used in connection with the
            Service and such systems and networks; · You agree not to attempt to
            decipher, decompile, disassemble, reverse engineer or otherwise
            investigate any of the software or components used to provide the
            Service for magicunbox.com ; · By accepting our terms of use you
            agree not to harm or threaten to harm other users on the page or
            staff of the MagicUnbox.com. You agree not to interfere with, or
            attempt to interfere with, the access of any user, host or network,
            including without limitation, by sending a virus, overloading,
            flooding, spamming, or mail-bombing the Service; · You are not
            allowed to provide payment information belonging to a third party; ·
            You can not use the Service in an abusive way contrary to its
            intended use, to MagicUnbox’s policies and instructions or to any
            applicable law; · You are not allowed to systematically retrieve
            data or other content from the page to create or compile, directly
            or indirectly, in single or multiple downloads, a collection,
            compilation, database, directory or the like, whether by manual
            methods, through the use of bots, crawlers, or spiders, or
            otherwise; · You are not to infringe third party intellectual
            property rights when using or accessing the Service, including but
            not limited to in making available virtual items by using the
            Service; · You can not make use of, promote, link to or provide
            access to materials deemed by MagicUnboxl at its sole discretion to
            be offensive or cause harm to MagicUnbox's reputation, including,
            but not limited to, illegal content and pornographic content and
            content deemed offensive or injurious to MagicUnbox.com and/or the
            Service (such as Warez sites, IRC bots and bittorent sites). General
            overview The following Terms & Conditions shall apply to the
            relationship between ASTRA SOLUTIONS N.V., all subsidiaries of ASTRA
            SOLUTIONS ., and www.MagicUnbox.com ("MagicUnbox"), and the end user
            ("You" or "Your", “Customer”, “User”). By using MagicUnbox services,
            You accept these Terms & Conditions, as well as the manner in which
            MagicUnbox operates. These Terms & Conditions constitute the entire
            agreement between MagicUnbox and You and supersede and replace all
            prior commitments, undertakings or representations, whether written
            or oral, between You and MagicUnbox with respect of Your use of
            MagicUnbox. Information on MagicUnbox, such as the "F.A.Q",
            "Support", "Tutorial" sections, are only recommendations and are not
            intended as rules or guidelines. MagicUnbox is not liable or
            responsible for the actions of users or other individuals who have
            read or been informed of such information or other written material.
            As is typical with casing, MagicUnbox cannot guarantee that You will
            be the winner of any individual item by purchasing discount cases.
            But, MagicUnbox can guarantee you will receive any item you received
            by “Open case” - casing button via our third party partners and
            prior to rules of delivery (see Delivery). Please check exactly what
            is content of every case before purchase.
            <br />
            If you have any questions, concerns, or comments about our Terms &
            Conditions, please email us at support@MagicUnbox.com Termination We
            keep the right to terminate or suspend access to our Service and/or
            your account immediately, without prior notice or liability, for any
            reason whatsoever, including without limitation if you breach the
            Terms. All provisions of the Terms which by their nature should
            survive termination, which are including, without limitation,
            ownership provisions, warranty disclaimers , indemnity and
            limitations of liability. Upon the termination, your right to use
            the Service will be immediately brought to an end. If you wish to
            terminate your account, you may simply discontinue using the
            Service. Links to Other Web Sites Our Service may contain links to
            third-party web sites or services that are not owned or controlled
            by MagicUnbox.com. MagicUnbox has no control over, and assumes no
            responsibility for, the content, privacy policies, or practices of
            any third-party websites or services. You further acknowledge and
            agree that MagicUnbox shall not be responsible or liable, directly
            or indirectly, for any damage or loss caused or alleged to be caused
            by or in connection with use of or reliance on any such content,
            goods or services available on or through any such web sites or
            services. We strongly advise you to read the terms and conditions
            and privacy policies of any third-party websites or services that
            you visit. Disclaimer of warranties This website is provided “as is”
            without any representations or warranties, express or implied.
            www.MagicUnbox.com makes no representations or warranties in
            relation to this website or the information and materials provided
            on this website. Without prejudice to the generality of the
            foregoing paragraph, www.MagicUnbox.com does not warrant that: this
            website will be constantly available, or available at all; or the
            information on this website is complete, true, accurate or
            non-misleading. Nothing on this website constitutes, or is meant to
            constitute, advice of any kind. If you require advice in relation to
            any legal, financial or medical matter you should consult an
            appropriate professional. MagicUnbox Wallet By topping up an amount
            to your MagicUnbox wallet, you accept that the credits received
            (labeled by the $-sign) are not worth real money and not subject to
            any refunds. Changes to Terms & Conditions MagicUnbox may change
            (add to, delete, or amend) the Terms & Conditions from time to time,
            with or without cause. Should this occur, You agree that MagicUnbox
            may provide You notice of such changes in any of the following ways:
            via an email from MagicUnbox, via a social media announcement,
            MagicUnbox’s blog, and/or by posting a change notice on the website
            for a reasonably limited time. It is Your responsibility to review
            any revised Terms & Conditions. Should You find any subsequent
            revisions to the Terms & Conditions unacceptable, You must cease
            using MagicUnbox services. By continuing to access, use, or download
            in any way any service from MagicUnbox following notice of a
            revision to the Terms & Conditions, You agree to and are bound by
            the Terms & Conditions as revised. Case Purchases / Casing /
            Case-O-Matic By open case (casing), you agree to receive one of
            random items inside. MagicUnbox using provably fair algorithms.
            Previous experience doesn’t affect chance in any next case to
            receive special individual item. MagicUnbox reserves the right to,
            from time to time, in its sole discretion, change the price of
            cases. MagicUnbox Product/Store Rules MagicUnbox distributor of the
            coupons (to acquire third party items) with the value to open the
            case User purchasing case coupons to open cases.
          </p>
          <p>
            MagicUnbox may in its sole discretion offer select products for
            direct purchase through the MagicUnbox Store. MagicUnbox reserves
            the right in its sole discretion to limit the number of products per
            user that may be purchased through the MagicUnbox Store. Products
            offered through the MagicUnbox Store are gift cards and discount
            coupons for the items unless specifically stated. MagicUnbox doesn’t
            own or store these items. They will be delivering via third parties,
            who will take our gift card in the user behalf. MagicUnbox reserves
            the right to, from time to time, at its sole discretion, change the
            price of gift cards in the store.
          </p>
          <p>
            The subject of purchase, winning items in the store is the
            certificate. It has no value and familiar as "Gift card" or
            "Discount coupon", which MagicUnbox store is changing to the items
            with partners. NO REAL ITEMS can be purchased or acquire.
          </p>
          <p>
            Returns / Cancellations MagicUnbox allows its customers to cancel
            the funds in accordance with MagicUnbox Refunds policies, which
            MagicUnbox may change from time to time in its sole discretion. If
            You cancel the order before it is processed, You will be entitled to
            a full refund. If the item is already shipped it is not possible to
            cancel the order. If You wish to cancel an order, please contact us
            at support@MagicUnbox.com or use this functionality in your personal
            profile. . . Should MagicUnbox not be able to deliver the product
            ordered or the product won for any reason, You will have the option
            to accept an alternative but comparable item, as determined by
            MagicUnbox in its sole discretion, or receive a refund of the
            transaction amount paid. Odds of Winning individual item via casing
            Every case is unique and the results of all cases offered on
            MagicUnbox depend on the provably fair algorithm. Participating in
            such purchases Do not affect the skill or previous cases experience.
            We try to set out our Terms and Conditions, Policies and Rules in a
            simple and transparent way. If you have any questions or issues
            relating to our Terms, Conditions, Policies or Rules, or the way
            they are presented, please don’t hesitate to contact us:
            support@MagicUnbox.com alternatively you can contact us directly on
            the website via our support section on https://MagicUnbox.com.
            Please make sure If you fully understand the process and the game
            terms. The purpose of these Terms and Conditions is: · to set out
            the basis on which MagicUnbox will allow the User access to the
            Website and the Services; · to inform the User about the protections
            that we provide to ensure that our cases are fair and transparent; ·
            to establish the parameters governing cases entries and settlement
            of the Pot. We may amend our Terms and Conditions from time to time
            so you should ensure that you have read and understood the Terms and
            Conditions which apply at the relevant time. Each time you use the
            Website, the Terms and Conditions which are in force at that time
            will apply to the contract between you and us. You should also print
            a copy of these Terms and Conditions, or save them to your computer,
            for future reference. In the event that our Terms and Conditions are
            updated, those published at the time of the Start Time of a open
            case will applicable to that case.
          </p>
          <p>
            User Accounts Registration When you initially register your account
            with MagicUnbox, you are required to provide certain personal data,
            including: Username · date of birth; · email address;
          </p>
          <p>
            We are required by law to collect and verify this information and we
            do our utmost to keep all personal data secure. For further
            information, please see our Privacy Policy.
          </p>
          <p>
            Each User may create only one User Account which is
            non-transferrable and you should ensure that access to your account
            is protected by keeping your password secret at all times. The
            personal information you provide to us on registering will be
            verified to ensure that you do not already have an existing User
            Account with Us. A User Account may only be used by the individual
            who created it and not by anyone else. We reserve the right to close
            your User Account if we have reason to believe that it or your User
            Account details is/are being used by anyone other than you and/or
            you have not kept your password confidential. You will compensate us
            for all and any losses, damages, costs and expenses we may suffer as
            a result of any failure by you to keep your Username and password
            strictly confidential.
            <br />
            For safety, we recommend right after registration turn on 2Factor
            Authorization. When you successfully register a User Account,
            MagicUnbox grants you a revocable, and non-exclusive licence to
            access and use the Website and the Services subject to the following
            conditions: · you agree to abide by the Terms and Conditions and
            give the User Warranties set out below; · you acknowledge that in no
            event will we, or any of our suppliers, accept any liability however
            arising for any losses you may incur as a result of accessing the
            Website and/or using the Services; · you acknowledge that MagicUnbox
            will hold certain personal data with respect to your identity,
            including but not limited to your name, address, date of birth and
            payment details. You agree that we rely on this information in
            entering into our agreement with you and you agree to hold us
            harmless against any falsehood or inaccuracy contained in the
            information you provide us; · you will not upload content to the
            Website if you do not own the copyright or have the express
            permission of the copyright owner to do so; · you will not disguise,
            or interfere in any way with, the IP Address of the device you are
            using to access the Website or otherwise take steps to prevent us
            from correctly identifying the actual IP Address of the device you
            are using whilst accessing the Website; · your use of the Website
            and our provision of the Services to you confer no rights whatsoever
            to the content and related intellectual property rights published on
            it; see paragraph 13 (Intellectual Property).
          </p>
          <p>
            If you reside in a country where online gambling is not a legal
            activity, you are not permitted to register an account or to play on
            the Website.
          </p>
          <p>
            You will not be able to participate in gambling if you are from a
            location where we believe it may not be legal for us to provide the
            Services, including the USA, France, China, Turkey, Australia and
            Singapore as well as any countries restricted by international
            sanctions. If you believe that we are blocking you from using the
            Website in a legal jurisdiction, please contact us:
            support@MagicUnbox.com User Warranties When you initially register a
            User Account, you are required to check and confirm your personal
            details, to confirm acceptance of these Terms and Conditions. In
            return for us agreeing to accept your application to register a User
            Account, you represent and warrant to MagicUnbox.com as follows: ·
            that you are 18 years of age or over, of sound mind and capable of
            taking responsibility for your own actions and that you can enter
            into a legally binding agreement with us; · that the personal data
            submitted in your application to register a User Account is true,
            correct, accurate and not misleading and you will keep it up to date
            at all times; · that you have not been convicted of any financial
            crime such as Money Laundering or Fraud; · that you have not been
            declared Bankrupt, have not entered into any formal voluntary
            arrangement(s) with creditors and are not currently subject to
            County Court Judgments; · that you are not in a period of
            self-exclusion (see our Responsible Gambling Policy) from any other
            gambling company. · that you will at all times whilst holding a User
            Account comply with all applicable legislation in the jurisdiction
            in which you are located; · that you will not at any time while
            holding a User Account seek to manipulate or in any way affect the
            result of any bet.
          </p>
          <p>
            If we believe that you have breached one or more of these User
            Warranties, your User Account may be investigated and this may
            result in Winnings you have won being forfeited and/or your User
            Account being suspended and/or closed. Age and identity verification
            It is an offence for any person under the age of 18 to gamble.
            MagicUnbox.com uses a third party services provider to verify the
            personal data you supply when you register a User Account to ensure
            that you are 18 or over. This may involve supplying the details that
            you have provided to us to an authorised data agency. In particular,
            we may verify that you are 18 years old or over, that you are
            resident in the country in which you say you are a resident and/or
            that your identity matches the identity of the sources of deposits
            in your User Account. If this is not the case, we will require
            further information from you to allow you to access the Website and
            will ask you for this by email or directly via the website.
          </p>
          <p>
            By agreeing to these Terms & Conditions, you authorise us to carry
            out these data checks through our third party services provider(s).
          </p>
          <p>
            You will not be able to make withdrawals from your User Account
            until age and identity verification has been successfully completed.
          </p>
          <p>
            Users who provide inaccurate or false information regarding their
            age or their identity may forfeit any Prizes won and will have their
            User Account closed. We also reserve the right to notify the
            relevant authorities which may result in civil proceedings or
            criminal prosecution. User responsibility and conduct From the time
            that you register your User Account up until it is closed (for
            whatever reason), you agree to adhere to all the Terms and
            Conditions set out herein and act in good faith in all your dealings
            with MagicUnbox.com.
          </p>
          <p>
            You agree that you will ensure that User Account details (including
            username, password and security information) are kept private and
            secure; you agree not to disclose them to any third party. If you
            suspect that someone may be misusing or attempting to misuse your
            User Account, you should contact us immediately: MagicUnbox
            @MagicUnbox.com You understand that you may lose money on bets
            placed on the Website and accept that you are fully responsible for
            any such loss. You accept that under no circumstances will any
            amounts lost by you as a result of any bet be recoverable by or from
            us and we have no liability whatsoever in connection with any such
            loss.
          </p>
          <p>
            You agree that you will not use the Website and/or the Services in
            any way that may lead to the encouragement, procurement or carrying
            out of any criminal or unlawful activity, or cause distress, harm or
            inconvenience to any other person.
          </p>
          <p>
            You will not select an offensive Username, make offensive comments,
            use offensive or pornographic material or make potentially
            defamatory or inflammatory remarks in connection with the chat or
            other social functions or any other part of the Website including
            within your own User Account information. We will not be liable to
            you for any loss that you may incur as a result of misuse of your
            password and we accept no liability resulting from the unauthorised
            use, whether fraudulent or otherwise of any User Account.
          </p>
          <p>
            Information accessed by you on our Website, including but not being
            limited to results, statistics, game data and fixtures are for your
            personal use only and the distribution or commercial exploitation of
            such information is strictly prohibited. Breach of these Terms and
            Conditions You agree to indemnify and hold us and our associated
            companies, affiliates, officers, directors, agents and employees
            harmless from any liabilities, claims, losses or demands made by any
            third party arising out of your breach of these terms and conditions
            or a breach by you of any law or the rights of any third party.
          </p>
          <p>
            In the event of such breach, your User Account may be suspended,
            investigated and Prizes you have won may be withheld and MagicUnbox
            may retain any positive balance then in your User Account pending
            the conclusions of such investigation and/or related legal
            proceedings. Investigations If We suspend your User Account and/or
            voids any of your bets, we will investigate the circumstances
            leading to this action as quickly as we can and will inform you
            promptly of the conclusion and the proposed remedy or action to be
            taken by MagicUnbox. In any such investigation, we will rely on the
            Rules, data provided to us by our data collection algorithms, data
            from game replays where available, and our transaction log database
            (and any other relevant information) as evidence to support our
            conclusions and actions.
          </p>
          <p>
            If you are unhappy about the conclusions of any such investigations
            or any actions we take as a result, please use our Complaints and
            Disputes procedures set out in this document.
          </p>
          <p>
            If you have funds deposited in a suspended or closed account, please
            contact us at MagicUnbox @MagicUnbox.com. However, we reserve the
            right to withhold the funds in your User Account pending the
            conclusion of any relevant investigation (including any relevant
            external investigation).
          </p>
          <p>
            We also reserve the right to supply Users’ details and the outcome
            of such investigations to any relevant authority to assist with an
            investigation by other parties as required by the Gambling
            (Licensing and Advertising) Act 2014 and its predecessors.
            Suspending or Closing accounts We may restrict your access to
            MagicUnbox, suspend or close your User Account and/or void any
            bets/entries you may have outstanding, at our absolute discretion
            if: · we suspect that you are under 18 (or have been under 18 whilst
            having an active User Account with Us); · we suspect that any of
            your Personal Data is inaccurate; · we are unable to verify your
            Personal Data; · we have a reason to believe you have registered
            and/or are using more than one account or are colluding with
            other(s); · we suspect you of cheating; · we suspect that you are
            engaging in any illegal and/or fraudulent activity; · we suspect
            that you have (or may have) breached any provision of these Terms
            and Conditions; · we suspect that you may be having difficulties
            obtaining credit or you are a problem gambler; · otherwise acted in
            breach of any law, statute, regulation or code of conduct or done
            anything which may put our licence granted by the Gambling
            Commission at risk; · we suspect you are acting in a manner that is
            detrimental to the conduct of our business or which may result in
            legal liability for you, us or a third party.
          </p>
          <p>
            Subject to Investigations, we are entitled to close your User
            Account and terminate these Terms and Conditions by giving you
            notice to your registered email address and, following such notice,
            you will no longer be able to access the Website or the Services
            other than to withdraw any outstanding balance in your User Account.
          </p>
          <p>
            Such termination shall be effective from the date on which notice to
            close your User Account is sent by us but will not affect any
            outstanding bets provided they are valid and not in breach of these
            Terms and Conditions. Bets made in breach of these Terms and
            Conditions will be void. In cases of suspected fraud or money
            laundering, a User may not be entitled to withdraw funds from a User
            Account.
          </p>
          <p>
            Any leftover monies in accounts closed by MagicUnbox as a result of
            integrity issues will be donated to a charity of our choosing. The
            decision to close accounts will be at the discretion of MagicUnbox
            after the relevant investigations have taken place. During this
            investigation period all funds belonging to the user will be frozen.
          </p>
          <p>
            If you are aware of any player cheating, or of any collusion taking
            place between players on MagicUnbox please contact
            support@MagicUnbox.com. Matters beyond our control Without prejudice
            to our obligations under the laws and regulations of the
            jurisdictions where we are licensed, we will not accept liability
            for loss or damage that a User may suffer as a result of a matter
            beyond our control including (but not limited to): · act of God; ·
            power cut; · war or terrorist activity, · riot and/or civil
            commotion, · invasion, terrorist attack or threat of terrorist
            attack, · war (whether declared or not) or threat of or preparation
            for war, · malicious damage, · fire and/or explosion, · flood or
            storm, · earthquake, subsidence, epidemic or other natural disaster,
            · nuclear accident, · act, failure or omission of any government or
            authority; · obstruction or failure of public or private
            telecommunication services and/or the internet; · any other delay or
            failure caused by a third party or otherwise outside of our control.
            If an event beyond our control occurs, we reserve the right to
            suspend or cancel the Services without incurring any liability. In
            the event of cancellation or suspension of the Services we will make
            every effort to inform you as soon as is reasonably practicable.
          </p>
          <p>
            MagicUnbox will not accept liability for the failure of equipment or
            software howsoever caused, wherever located or administered, whether
            under our direct control or not, that may prevent the provision of
            the Services or prevent Users from being able to contact us
            remotely. Lost/Forgotten Username/Password If you lose or forget
            your username or password please follow the password recovery
            instructions on the Website.
          </p>
          <p>
            at any time you are unable to log into your account, please contact
            us to request assistance support@MagicUnbox.com. Operation of the
            Website and the Services The Website and the Services MagicUnbox
            allows Users to access the game as provided and managed by Us.
            Access to the Website is subject to these Terms & Conditions and the
            cases are governed by the Rules. Please read both carefully before
            entering any game and contact Us if you have any questions:
            support@MagicUnbox.com. MagicUnbox does not endorse any third party
            interfaces that provide access to MagicUnbox ‘s services. Any user
            using third party software which accesses our facilities should know
            that this may not display the full information about your gambles.
            We provide our Services exclusively through Remote Gambling Terms
            You are gambling via an electronic form of communication and
            consequently you should be aware that: · You may be using a
            connection or equipment which is slower than such equipment used by
            others and this may affect your performance in time critical
            products (e.g. in-play gaming); · Where problems occur in the
            software or hardware used by us to provide the services we will take
            all reasonable steps to remedy the problem as soon as reasonably
            practicable. Where such problems cause a game to be interrupted in
            circumstances where it cannot be restarted from exactly the same
            position without any detriment to you or other players, we will take
            all reasonable steps to treat you in a fair manner (which may
            include reinstating the balance on your account to the position
            existing following completion of the last bet or game logged on our
            server immediately prior to the occurrence of the problem). · Third
            party software will only be permitted if it is being used through
            our API. Errors Although We will make every effort to ensure
            complete accuracy of results, calculation and payouts of winnings,
            we will not be held responsible for errors in the information on the
            Website which we do not directly control including, without
            limitation, results or event Start Times of events. If a result used
            to settle an event is changed after the settlement of a Market for
            any reason (other than an error by us) such as disqualifications or
            the suspicion of criminal or fraudulent activity, the results of the
            case will stand. If a payout has been awarded to a User in error, we
            reserve the right to make an appropriate adjustment to that User’s
            account balance to correct such error(s). If a User has insufficient
            funds to make this adjustment, the User agrees to make the necessary
            funds available to correct the error as soon as is practically
            possible. Financial transactions Deposits We support the following
            methods of depositing into a User Account with a minimum deposit of
            5 EUR: · credit cards; · debit cards;
          </p>
          <p>
            There are currently no charges associated with any payment method,
            these may change at a later date. We will not be responsible for
            additional charges which may be made by your credit card or bank
            when you make a deposit so please raise any concerns directly with
            the relevant institution.
          </p>
          <p>
            If a User wishes to deposit funds using a credit or debit card, the
            Cardholder's name and address MUST be the same as the name and
            address registered with the User Account.
          </p>
          <p>
            Users are encouraged to set a deposit limit on deposit. If you wish
            to change your deposit limit you can do so via the settings page or
            by contacting us: support@MagicUnbox.com. Any change in these limits
            will require at least 24 hours notice and may require further
            verification.
          </p>
          <p>
            We do not store your payment details on our Website. All payment
            details are stored and processed on secure SSL servers by our
            Payment Service Provider who holds a valid PCI-DSS certificate.
            Currency All financial transactions (including Deposits,
            Withdrawals, Wagers and Payouts) conducted through MagicUnbox will
            be in £, € or $. Anti-Money Laundering Regulations As required by
            Anti-Money Laundering Regulations, We reserve the right to raise
            queries regarding any aspect of your financial transactions on the
            Website including (but not limited to) the source of funds deposited
            in your User Account and the destination account of withdrawals.
            Your account may be suspended or closed if you fail to provide
            adequate evidence of the source of funds you have deposited with Us
            if asked. Communication Notices and announcements from MagicUnbox to
            Users There are several methods of communication on the Website and
            We make every effort to allow Users to keep such communication to a
            bare minimum if they so choose. The types of communication a User
            may receive from Us include: You agree to receiving all information
            from us electronically, by phone, by email, using the email address
            that is registered to your User Account. It is your responsibility
            to keep your contact details up-to-date on your User Account. If
            those details are incorrect or not up-to-date you may not receive
            important information relating to your User Account, the Services or
            changes to these Terms and Conditions. Any notice given by you to
            us, or by us to you, will be deemed received and properly served
            immediately when posted on our Website or 24 hours after an e-mail
            is sent. In proving the service of any notice, it will be sufficient
            to provide evidence, in the case of an e-mail, that such e-mail was
            sent to the specified e-mail address of the addressee. The
            provisions of this paragraph shall not apply to the service of any
            proceedings or other documents in any legal action. If we need to
            notify you directly under these Terms and Conditions, we will do so
            by email to the email address registered to your User Account
          </p>
          <p>
            Limitation of liability Nothing in these Terms and Conditions limits
            or excludes our liability for: · death or personal injury caused by
            our negligence; · fraud or fraudulent misrepresentation; and/or ·
            any other liability which cannot be excluded or limited by
            applicable law.
          </p>
          <p>
            If we fail to comply with these Terms and Conditions, we will accept
            responsibility for loss or damage you may suffer that is a
            foreseeable result of such a breach or our negligence. But under no
            circumstance (save as set out above) will we accept responsibility
            for any loss or damage that is not foreseeable (including indirect,
            special or consequential loss).
          </p>
          <p>
            Our total liability to you in respect of all other losses arising
            under or in connection with these Terms and Conditions, your User
            Account and your use of the Website, whether in contract, tort
            (including negligence), breach of statutory duty, or otherwise,
            shall in no circumstances exceed the amount held in your User
            Account at the time of such breach.
          </p>
          <p>
            We will not accept ANY liability for the failure of any equipment or
            software howsoever caused that may prevent the operation of the
            Website, or our Services or may impede the placing of offers for
            bets on the Website or prevent you from being able to contact us.
          </p>
          <p>
            Except as expressly stated in these Terms and Conditions and to the
            extent permitted by law, we do not give any representation,
            warranties or undertakings in relation to your use of the Website.
            Any representation, condition or warranty which might be implied or
            incorporated into these Terms and Conditions by statute, common law
            or otherwise is excluded to the fullest extent permitted by law.
            Suspension of the Service Our Services may occasionally be suspended
            for one of a number of reasons including (but not limited to): ·
            because we are carrying out essential maintenance. We will try and
            give you warning when this is going to happen and we will try and
            restrict it to times when there are low activity levels on the
            Website. · because of circumstances beyond our control. If this is
            the case we will do our best to inform you of the nature and likely
            length of the loss of the Services. · because we have been required
            to suspend the Services by a regulator or other relevant authority.
            We will not accept responsibility for losses Users may incur as a
            result of suspension of the Services.
          </p>
          <p>
            This will always be done at the discretion of MagicUnbox whose
            decisions will be based on ensuring that no customer is at a
            disadvantage. MagicUnbox will always aim to keep you informed of any
            major disruptions with our services. Complaints and Disputes We will
            try to deal with all Users fairly at all times but if you have a
            complaint please contact us: support@MagicUnbox.com, or
            alternatively you can contact us directly on our website on our
            support section and we will try and address your issue(s) quickly
            and fairly.
          </p>
          <p>
            In emails to us please include your username and full name, and
            ensure that the email address you use is the one that you have
            registered on your User Account. Please also include a clear
            explanation of your complaint. The MagicUnbox team will investigate
            your complaint and respond to you as quickly as possible.
          </p>
          <p>
            Intellectual Property All intellectual property rights on the
            Website including, without limitation, all copyright and related
            rights, design rights and all trademark rights (whether registered
            or unregistered) in and to the website, software, images and text
            and the domain name http://www.MagicUnbox.com, (altogether the "IP
            Rights") will, at all times, belong to Us.
          </p>
          <p>
            Under the terms of the licence granted under these Terms and
            Conditions, Users are permitted to use the website only as expressly
            authorised by Us. You agree not to copy, redistribute, publish,
            reproduce, transmit, display, commercially exploit, tamper with or
            create derivative works of any of the IP Rights in whole or in part
            and you agree not to interfere with, modify or reverse engineer any
            software provided on the Website.
          </p>
          <p>
            You acknowledge and agree that the material and content contained
            within the Website and provided as part of the Services are made
            available for your personal non-commercial use only. Any other use
            of such material and content is prohibited. General Terms Amendments
            to these Terms & Conditions MagicUnbox reserves the right to change
            the Terms and Conditions to reflect changes to the Website and/or
            the Services, including the addition of products or services offered
            by us or a third party. We will announce and publish any changes on
            the Website and we will give you advance notice (by, at our sole
            discretion-emailing you and providing a notification on the Website
            or publishing a notice and the revised terms and conditions on the
            Website). Your continued use of the Website and the Services
            following such notification or such advance notice will be deemed
            binding acceptance of the modification(s). If a change is
            unacceptable to you, you should not continue to use the Website
            and/or the Services. It is your sole responsibility to review the
            Terms and Conditions (including the Rules) and any amendments to
            them each time you use the Website and/or the Services. Applicable
            Law The Website, the Services, the Terms and Conditions, our
            Policies and the Rules are available only in English. In the event
            of a conflict with any other Policy Severability If any provision of
            these Terms & Conditions is deemed by any competent authority to be
            unenforceable or invalid, the relevant provision will be modified to
            allow it to be enforced in line with the intention of the original
            text to the fullest extent permitted by applicable law. The validity
            and enforceability of the remaining provisions of these Terms &
            Conditions shall not be affected. No Waiver or Delay No failure or
            delay by a party to exercise any of its rights under these Terms &
            Conditions shall operate as a waiver thereof and no single or
            partial exercise of any such right shall prevent any other or
            further exercise of that or any other right. Assignment You may not
            assign these Terms and Conditions to any third party. We may assign,
            transfer or novate any or all of its rights and obligations under
            these Terms and Conditions to any third party at any time without
            notice to you. Our rights Notwithstanding any other terms set out in
            these Terms and Conditions, in providing the Services we will be
            entitled to take any action as we consider necessary in our absolute
            discretion to ensure compliance with all applicable legislation. ,
            ,The Last update of Terms and Conditions: 12.12.2018
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsPage;
