export const SET_VISIBLE_POPUP = "SET_VISIBLE_POPUP";
export const SET_AUTH_FORM = "SET_AUTH_FORM";
export const GET_DONATION_MONEY = "GET_DONATION_MONEY";
export const SET_STATUS_TOP_LISTS = "SET_STATUS_TOP_LISTS";
export const SET_STATUS_ENVELOPE = "SET_STATUS_ENVELOPE";
export const SET_USERS = "SET_USERS";
export const SET_TODAY_USERS = "SET_TODAY_USERS";
export const SET_MONTH_USERS = "SET_MONTH_USERS";
export const GET_REAL_TIME_USERS = "GET_REAL_TIME_USERS";
export const SET_EDIT_POPUP_STATUS = "SET_EDIT_POPUP_STATUS";
export const SET_DISPLAY_USER = "SET_DISPLAY_USER";
export const SET_NOTIFICATION_USERS = "SET_NOTIFICATION_USERS";
export const SET_LAST_USER = "SET_LAST_USER";
export const SET_REMOVED_NOTIFICATIONS = "SET_REMOVED_NOTIFICATIONS";
export const SET_FILTERED_NOTIFICATIONS = "SET_FILTERED_NOTIFICATIONS";
export const SET_ERROR_POPUP_STATUS = "SET_ERROR_POPUP_STATUS";
export const SET_VISIBLE_PROTECT_POPUP = "SET_VISIBLE_PROTECT_POPUP";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const SET_EDIT_TOP_DONATORS = "SET_EDIT_TOP_DONATORS";
export const SET_EDIT_MONTH_WINNER = "SET_EDIT_MONTH_WINNER";
export const SET_PENDING_USERS = "SET_PENDING_USERS"

export function setVisiblePopUp(visible) {
  const action = {
    type: SET_VISIBLE_POPUP,
    visible: visible
  };
  return action;
}
export function setAuthForm(data) {
  const action = {
    type: SET_AUTH_FORM,
    data: data
  };
  return action;
}

export function getDonationMoney(money) {
  const action = {
    type: GET_DONATION_MONEY,
    money: money
  };
  return action;
}

export function setVisibleTopLists(status) {
  const action = {
    type: SET_STATUS_TOP_LISTS,
    status: status
  };
  return action;
}

export function setStatusEnvelope(envelope) {
  const action = {
    type: SET_STATUS_ENVELOPE,
    envelope: envelope
  };
  return action;
}

export function setUsers(users) {
  const action = {
    type: SET_USERS,
    users: users
  };
  return action;
}

export function setTodayUsers(users) {
  const action = {
    type: SET_TODAY_USERS,
    users: users
  };
  return action;
}

export function setMonthUsers(users) {
  const action = {
    type: SET_MONTH_USERS,
    users: users
  };
  return action;
}

export function getRealTimeUsers(users) {
  const action = {
    type: GET_REAL_TIME_USERS,
    users: users
  };
  return action;
}

export function setEditPopUpStatus(status) {
  const action = {
    type: SET_EDIT_POPUP_STATUS,
    status: status
  };
  return action;
}

export function setDisplayUser(status) {
  const action = {
    type: SET_DISPLAY_USER,
    status: status
  };
  return action;
}

export function setNotificationUsers(users) {
  const action = {
    type: SET_NOTIFICATION_USERS,
    users: users
  };
  return action;
}

export function setLastUser(user) {
  const action = {
    type: SET_LAST_USER,
    user: user
  };
  return action;
}

export function setRemovedNotifications(item) {
  const action = {
    type: SET_REMOVED_NOTIFICATIONS,
    item: item
  };
  return action;
}

export function setErrorPopupStatus(status) {
  const action = {
    type: SET_ERROR_POPUP_STATUS,
    status: status
  };
  return action;
}

export function setFilteredNotifications(item) {
  const action = {
    type: SET_FILTERED_NOTIFICATIONS,
    item: item
  };
  return action;
}

export function setVisibleProtectPopup(visible) {
  const action = {
    type: SET_VISIBLE_PROTECT_POPUP,
    visible: visible
  };
  return action;
}

export function setLanguage(language) {
  const action = {
    type: SET_LANGUAGE,
    language: language
  };
  return action;
}

export function setEditTopDonators(status) {
  const action = {
    type: SET_EDIT_TOP_DONATORS,
    status: status
  };
  return action;
}

export function setEditMonthWinner(status) {
  const action = {
    type: SET_EDIT_MONTH_WINNER,
    status: status
  };
  return action;
}

export function setPendingUsers(user) {
  const action = {
    type: SET_PENDING_USERS,
    user: user
  };
  return action;
}
