export const signIn = credentials => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    firebase
      .auth()
      .signInWithEmailAndPassword(credentials.email, credentials.password)
      .then(() => {
        dispatch({ type: "LOGIN_SUCCESS" });
      })
      .catch(err => {
        dispatch({ type: "LOGIN_ERROR", err });
      });
  };
};

export const signOut = () => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch({ type: "SIGNOUT_SUCCESS" });
      });
  };
};

export const signUp = newUser => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    firebase
      .auth()
      .createUserWithEmailAndPassword(newUser.email, newUser.password)
      .then(resp => {
        return firestore
          .collection("users")
          .doc(resp.user.uid)
          .set({
            username: newUser.username,
            message: newUser.message,
            password: newUser.password,
            email: newUser.email,
            country: newUser.country,
            donation: newUser.donation,
            date: newUser.date
          });
      })
      .then(() => {
        dispatch({ type: "SIGNUP_SUCCESS" });
      })
      .catch(err => {
        dispatch({ type: "SIGNUP_ERROR", err });
      });
  };
};

export const setNotifications = function(status) {
  let not = JSON.stringify(status);
  localStorage.setItem("notifications", not);
};

export const getNotifications = () => {
  let notString = localStorage.getItem("notifications");
  let currentNot = JSON.parse(notString);
  return currentNot;
};

export const deleteNotifications = function(status) {
  localStorage.setItem("removed", JSON.stringify(status));
};

export const getDeletedNotifications = () => {
  let notString = localStorage.getItem("removed");
  let currentNot = JSON.parse(notString);
  return currentNot;
};
