import React, { Component } from "react";
import "./protectPopup.css";
import { connect } from "react-redux";
import { setVisibleProtectPopup } from "../../../actions/action";
import CloseIcon from "../../../img/cancel_icon.png";

class ProtectPopup extends Component {
  closeComponent = () => {
    this.props.setVisibleProtectPopup(false);
  };
  render() {
    return (
      <div className="protectPopup_wrapper">
        <img
          src={CloseIcon}
          alt="CloseIcon"
          onClick={this.closeComponent}
          className="closeProtectPopupImg"
        />
        <div className="protectPopup_holder">
          <h2>GLOBAL INTERNET SECRITY</h2>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
};

export default connect(mapStateToProps, { setVisibleProtectPopup })(
  ProtectPopup
);
