import React, { Component } from "react";
import "./form.css";
import moment from "moment";
import { getCountries } from "./../../config/countries";
import PaypalRender from "../../paypal/PaypalRender";
import { connect } from "react-redux";
import { setVisiblePopUp, setNotificationUsers } from "../../actions/action";
import { createDonator } from "../../actions/formAction";
import CloseIcon from "../../img/cancel_icon.png";

const languages = {
  en: require("../../languages/en.json"),
  fr: require("../../languages/fr.json"),
  es: require("../../languages/es.json"),
  ru: require("../../languages/ru.json"),
  cn: require("../../languages/cn.json")
};

const allCountries = getCountries();
var submitInterval = null;

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // User data
      username: false,
      email: false,
      password: false,
      message: false,
      country: false,
      donation: this.props.amount,
      date: "",
      time: "",
      // Error messages
      usernameError: false,
      emailError: false,
      passwordError: false,
      messageError: false,
      countryError: false,
      donationError: false,
      wrongPass: false
    };
  }

  getInputValue = e => {
    // Get users data and set in state
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  getCurrentTime = () => {
    // Get current time and set in state
    const date = moment().format();
    this.setState({
      date
    });
  };

  // Submitted function
  handleSubmit = e => {
    e.preventDefault();
    let usersEmail = [];
    let usersPassword = [];
    this.props.realTimeUsers.map(user => {
      usersEmail.push(user.email);
      usersPassword.push(user.password);
    });
    if (
      !this.state.email ||
      !this.state.password ||
      !this.state.username ||
      !this.state.message ||
      !this.state.country
    ) {
      if (!this.state.email) {
        this.setState({
          emailError: true
        });
      }
      if (!this.state.password || this.state.password.length < 6) {
        this.setState({
          passwordError: true
        });
      }
      if (!this.state.username) {
        this.setState({
          usernameError: true
        });
      }
      if (!this.state.message) {
        this.setState({
          messageError: true
        });
      }
      if (!this.state.country) {
        this.setState({
          countryError: true
        });
      }
    } else {
      if (
        usersEmail.includes(this.state.email) &&
        !usersPassword.includes(this.state.password)
      ) {
        this.setState({
          wrongPass: true
        });
      } else {
        if (
          usersEmail.includes(this.state.email) &&
          usersPassword.includes(this.state.password)
        ) {
          this.setState({
            wrongPass: false
          });
          // Send all data to firebase
          this.submitData();
        } else {
          // Send all data to firebase
          this.submitData();
        }
      }
    }
  };

  submitData = () => {
    // Send current time to firebase
    this.getCurrentTime();
    // Get donator amount for paypal
    // Send all data to firebase
    submitInterval = setInterval(() => {
      // donator data for firebase
      let newState = {
        email: this.state.email,
        password: this.state.password,
        username: this.state.username,
        donation: this.state.donation,
        message: this.state.message,
        country: this.state.country,
        date: this.state.date
      };
      if (this.state.date !== "") {
        this.props.createDonator(newState);
        clearInterval(submitInterval);
        // Close form component
        this.props.setVisiblePopUp(false);
        this.props.returnAmountToZero("");
        localStorage.setItem("Success donation", "RichOnlyClub");
      }
    }, 500);
  };

  handleClear = e => {
    this.setState({
      [e.target.id + "Error"]: false
    });
  };

  getFlagAndContry = e => {
    // Add and remove active class
    var elems = document.querySelectorAll(".active");
    [].forEach.call(elems, function(el) {
      el.classList.remove("active");
    });
    e.target.className = "active";

    if (e.target.className === "active") {
      this.setState({
        countryError: false
      });
    }

    // Get src and name of country
    let src = e.target.childNodes[0].src;
    let name = e.target.childNodes[1].data;
    let flag_country = [];
    flag_country.push(src, name);
    this.setState({
      country: flag_country
    });
  };

  closeForm = () => {
    // Close form component on click "X"
    this.props.setVisiblePopUp(false);
    this.props.returnAmountToZero("");
  };

  render() {
    let local = localStorage.getItem("activeLang");
    return (
      <div className="create_acc">
        {languages[local].form_page.map((item, index) => (
          <div className="create_acc_content" key={index}>
            <img
              src={CloseIcon}
              alt="CloseIcon"
              onClick={this.closeForm}
              className="closeFormPopupImg"
            />
            <div className="container">
              <form onSubmit={this.handleSubmit} className="form_signUp">
                <h2>{item.donationTitle}</h2>
                <div className="create_acc_wrap">
                  <div className="create_acc_inputs form_box">
                    <div className="create_acc_row">
                      <label>{item.email}:</label>
                      <input
                        type="email"
                        id="email"
                        onChange={this.getInputValue}
                        onFocus={this.handleClear}
                      />
                      {this.state.emailError ? (
                        <p className="validation_p">{item.emailError}</p>
                      ) : null}
                    </div>
                    <div className="create_acc_row">
                      <label>{item.password}:</label>
                      <input
                        type="password"
                        id="password"
                        onChange={this.getInputValue}
                        onFocus={this.handleClear}
                      />
                      {this.state.passwordError ? (
                        <p className="validation_p">{item.passwordError}</p>
                      ) : null}
                      {this.state.wrongPass ? (
                        <p className="validation_p">{item.passwordWrong}</p>
                      ) : null}
                    </div>
                    <div className="create_acc_row">
                      <label>{item.username}:</label>
                      <input
                        type="text"
                        id="username"
                        onChange={this.getInputValue}
                        onFocus={this.handleClear}
                      />
                      {this.state.usernameError ? (
                        <p className="validation_p">{item.messageError}</p>
                      ) : null}
                    </div>
                    <div className="create_acc_row">
                      <label>{item.donation}:</label>
                      <input
                        type="number"
                        defaultValue={this.props.amount}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="create_acc_absolute">
                    <button>{item.donateNow}!</button>
                    {/* Paypal button */}
                    {/* <PaypalRender /> */}
                  </div>
                  <div className="create_acc_textareaFlags form_box">
                    <div className="create_acc_row">
                      <label>{item.message}:</label>
                      <textarea
                        maxLength="200"
                        id="message"
                        onChange={this.getInputValue}
                        onFocus={this.handleClear}
                      ></textarea>
                      {this.state.messageError ? (
                        <p className="validation_p">{item.messageError}</p>
                      ) : null}
                    </div>
                    <div className="flags_list">
                      <label>{item.country}:</label>
                      {/* Map all countries */}
                      <ul className="country_list">
                        {allCountries.map((country, index) => (
                          <li key={index} onClick={this.getFlagAndContry}>
                            <img
                              alt="country"
                              className="flags"
                              src={
                                "http://flagpedia.net/data/flags/normal/" +
                                country.code.toLowerCase() +
                                ".png"
                              }
                            />
                            {country.name}
                          </li>
                        ))}
                      </ul>
                      {this.state.countryError ? (
                        <p className="validation_p">{item.countryError}</p>
                      ) : null}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
};

export default connect(mapStateToProps, {
  createDonator,
  setVisiblePopUp,
  setNotificationUsers
})(Form);
