import React, { Component } from "react";
import "./monthWinner.css";
import { connect } from "react-redux";
import {
  setMonthUsers,
  setUsers,
  setEditMonthWinner
} from "../../actions/action";
import { db } from "../../config/fbConfig";
import moment from "moment";

import level1 from "../../img/level1.png";
import level2 from "../../img/level2.png";
import level3 from "../../img/level3.png";
import EditMonthWinner from "./EditMonthWinner";

const current_month = moment()
  .format()
  .substring(0, 7);

class MonthWinner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updatedUser: [],
      users: []
    };
  }

  componentWillUnmount() {
    this.props.setEditMonthWinner(false);
  }

  componentDidMount() {
    this.getUsersFromFireBase();
  }

  getUsersFromFireBase = () => {
    db.collection("donators").onSnapshot(querySnapshot => {
      let IDs = [];
      let data = querySnapshot.docs.map(doc => {
        IDs.push(doc.id);
        return doc.data();
      });

      let dataWithID = data.map((item, index) => {
        item.id = IDs[index];

        return item;
      });

      this.props.setUsers(dataWithID);

      const currentMonth = this.props.getUsers.filter(
        user => user.date.substring(0, 7) === current_month
      );
      this.props.setMonthUsers(currentMonth);
    });
  };

  setLevel = user => {
    if (user.donation < 25) {
      return level1;
    } else if (user.donation > 25 && user.donation < 100) {
      return level2;
    } else {
      return level3;
    }
  };

  title_img = user => {
    if (user.donation < 25) {
      return "Rich level 3";
    } else if (user.donation > 25 && user.donation < 100) {
      return "Rich level 2";
    } else {
      return "Rich level 1";
    }
  };

  grabStateFromChild = userForEdit => {
    let newUsers = [];
    this.state.users.map(user => {
      if (user.id === userForEdit.id) {
        user = userForEdit;
      }
      return newUsers.push(user);
    });

    this.setState({
      users: newUsers
    });
  };

  openEditPopup = user => {
    this.setState({
      updatedUser: user
    });
    if (this.props.getEditMonthWinner === false) {
      this.props.setEditMonthWinner(true);
      console.log(this.props, "aca");
    } else {
      this.props.setEditMonthWinner(false);
      console.log(this.props, "aca");
    }
  };

  render() {
    console.log("WINNER", this.props.getMonthUsers);
    return (
      <div className="monthWinner_component">
        <h2>Month Winner</h2>
        {this.props.getMonthUsers.length
          ? this.props.getMonthUsers
              .sort((a, b) => parseFloat(b.donation) - parseFloat(a.donation))
              .slice(0, 1)
              .map((item, index) => (
                <div key={index} className="monthWinner_component_item_holder">
                  <div className="month_winner_component_wrap_holder">
                    <div className="month_winner_table_header">
                      <p>Name:</p>
                      <p>Message:</p>
                      <p>Donation:</p>
                      <p>Country:</p>
                    </div>
                    <div className="monthWinner_component_item">
                      <p className="monthWinner_component_item_content_title">
                        {item.username}
                        <img
                          src={this.setLevel(item)}
                          alt="kruna"
                          title={this.title_img(item)}
                        />
                      </p>
                      <p className="monthWinner_component_item_content_text">
                        {item.message}
                      </p>
                      <p
                        className="monthWinner_component_item_content_price"
                        title="Full Amount Monthly Winner Has Access To"
                      >{`${item.donation}$`}</p>
                      <img
                        className="monthWinner_img"
                        src={item.country[0]}
                        title={item.country[1]}
                      />
                    </div>
                  </div>
                  <button
                    className="monthWinner_component_item_button"
                    onClick={() => this.openEditPopup(item)}
                  >
                    Edit
                  </button>
                </div>
              ))
          : null}
        {this.props.getEditMonthWinner ? (
          <EditMonthWinner
            user={this.state.updatedUser}
            grabStateFromChild={this.grabStateFromChild}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
};

export default connect(mapStateToProps, {
  setMonthUsers,
  setUsers,
  setEditMonthWinner
})(MonthWinner);
