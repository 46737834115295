import React, { Component } from "react";
import "./edit.css";
import cancel_icon from '../../img/cancel_icon.png';
import { getCountries } from "./../../config/countries";
import { connect } from "react-redux";
import { setEditPopUpStatus } from "../../actions/action";
import { db } from "../../config/fbConfig";

const allCountries = getCountries();

class Edit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // All property from donator
      id: this.props.user.id,
      username: this.props.user.username,
      password: this.props.user.password,
      email: this.props.user.email,
      message: this.props.user.message,
      country: this.props.user.country,
      donation: this.props.user.donation,
      date: this.props.user.date,
      time: this.props.user.time
    };
  }

  handleChange = e => {
    // get new value of current element and set value in state
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleSubmit = () => {
    // Update firebase collection
    db.collection("donators")
      .doc(this.props.user.id)
      .update({
        username: this.state.username,
        password: this.state.password,
        email: this.state.email,
        message: this.state.message,
        country: this.state.country,
        donation: this.state.donation,
        date: this.state.date,
        time: this.state.time
      });
    // Get state and sent to users component
    this.props.grabStateFromChild(this.state);
    this.props.setEditPopUpStatus(false);
  };

  getFlagAndContry = e => {
    // Add and remove active class
    var elems = document.querySelectorAll(".active");
    [].forEach.call(elems, function(el) {
      el.classList.remove("active");
    });
    e.target.className = "active";

    // Get src and name of country and set in state
    let src = e.target.childNodes[0].src;
    let name = e.target.childNodes[1].data;
    let flag_country = [];
    flag_country.push(src, name);
    this.setState({
      country: flag_country
    });
  };

  closeEditForm = () => {
    this.props.setEditPopUpStatus(false);
  };

  render() {
    return (
      <div className="edit_wrapper">
        <div className="editComponent_close" onClick={this.closeEditForm}>
          <img src={cancel_icon} />
        </div>
        <div className="edit_page_form">
          <div className="edit_row">
            <label htmlFor="username">Username</label>
            <input
              id="username"
              value={this.state.username}
              onChange={this.handleChange}
            />
          </div>
          <div className="edit_row">
            <label htmlFor="password">Password</label>
            <input
              type="text"
              value={this.state.password}
              id="password"
              onChange={this.handleChange}
            />
          </div>
          <div className="edit_row">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              value={this.state.email}
              id="email"
              onChange={this.handleChange}
            />
          </div>
          <div className="edit_row">
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              value={this.state.message}
              onChange={this.handleChange}
            ></textarea>
          </div>
          <div>
            <ul className="edit_slider">
              {allCountries.map((country, index) => (
                <div key={index} onClick={this.getFlagAndContry}>
                  <img
                    alt="country"
                    className="flags"
                    src={
                      "http://flagpedia.net/data/flags/normal/" +
                      country.code.toLowerCase() +
                      ".png"
                    }
                  />
                  {country.name}
                </div>
              ))}
            </ul>
          </div>
          <div className="edit_row">
            <label htmlFor="donation">Donation</label>
            <input
              id="donation"
              type="number"
              value={this.state.donation}
              onChange={this.handleChange}
            />
          </div>
          <div className="edit_row">
            <label htmlFor="time">Date and Time</label>
            <input
              placeholder={`Format: 02/09/2019`}
              id="date"
              onChange={this.handleChange}
              value={this.state.date}
            />
          </div>
          <button className="submit_edit" onClick={this.handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
};

export default connect(mapStateToProps, { setEditPopUpStatus })(Edit);
