const initState = {};

const donatorReducer = (state = initState, action) => {
  switch (action.type) {
    case "CREATE_DONATOR":
      console.log("created donator", action.donator);
      return state;
    case "CREATE_DONATOR_ERROR":
      console.log("created donator error", action.err);
      return state;
    default:
      return state;
  }
};

export default donatorReducer;
