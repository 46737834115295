import React, { Component } from "react";
import "./topListError.css";
import { connect } from "react-redux";
import { setErrorPopupStatus } from "../../../actions/action";

class TopListError extends Component {
  closePopUp = () => {
    this.props.setErrorPopupStatus(false);
  };

  render() {
    const { serbianLanguage } = this.props;
    return (
      <div className="topList_error_wrapper">
        <div className="topList_error">
          <p>
            {serbianLanguage
              ? "Listu mogu videti samo bogati ljudi !"
              : "Only rich people can view list !"}
          </p>
          <button onClick={this.closePopUp}>OK</button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
};

export default connect(
  mapStateToProps,
  { setErrorPopupStatus }
)(TopListError);
