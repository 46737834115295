export const createDonator = donator => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection("donators")
      .add({
        ...donator
      })
      .then(() => {
        dispatch({ type: "CREATE_DONATOR", donator });
      })
      .catch(err => {
        dispatch({ type: "CREATE_DONATOR_ERROR", err });
      });
  };
};
