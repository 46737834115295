import React, { Component } from "react";
import "../AdminTopDonators/editTopDonatros.css";
import cancel_icon from "../../img/cancel_icon.png";
import { getCountries } from "../../config/countries";
import { db } from "../../config/fbConfig";

//Redux
import { connect } from "react-redux";
import { setEditMonthWinner } from "../../actions/action";

const allCountries = getCountries();

class EditTopDonators extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.user.id,
      username: this.props.user.username,
      message: this.props.user.message,
      country: this.props.user.country
    };
  }

  handleChange = e => {
    // get new value of current element and set value in state
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleSubmit = () => {
    // Update firebase collection
    db.collection("donators")
      .doc(this.props.user.id)
      .update({
        username: this.state.username,
        message: this.state.message,
        country: this.state.country
      });
    // Get state and sent to users component
    this.props.grabStateFromChild(this.state);
    this.props.setEditMonthWinner(false);
    console.log(this.props.getEditMonthWinner, "getEditMonthWinner");
  };

  closePopup = () => {
    this.props.setEditMonthWinner(false);
    console.log(this.props.getEditMonthWinner, "getEditMonthWinner");
  };

  getFlagAndContry = e => {
    // Add and remove active class
    var elems = document.querySelectorAll(".active");
    [].forEach.call(elems, function(el) {
      el.classList.remove("active");
    });
    e.target.className = "active";

    // Get src and name of country and set in state
    let src = e.target.childNodes[0].src;
    let name = e.target.childNodes[1].data;
    let flag_country = [];
    flag_country.push(src, name);
    this.setState({
      country: flag_country
    });
  };

  render() {
    return (
      <div className="edit_top_donators_wrapper">
        <div className="edit_top_donators_holder">
          <img
            src={cancel_icon}
            className="edit_top_donators_close"
            onClick={this.closePopup}
          />
          <div className="edit_top_donator_label">
            <p>Username</p>
            <input
              type="text"
              id="username"
              value={this.state.username}
              onChange={this.handleChange}
            />
          </div>
          <div className="edit_top_donator_label">
            <p>Message</p>
            <textarea
              id="message"
              value={this.state.message}
              onChange={this.handleChange}
            ></textarea>
          </div>
          <div className="edit_top_donator_flags">
            {allCountries.map((country, index) => (
              <div
                key={index}
                className="edit_top_donator_flags_row"
                onClick={this.getFlagAndContry}
              >
                <img
                  alt="country"
                  className="flags"
                  src={
                    "http://flagpedia.net/data/flags/normal/" +
                    country.code.toLowerCase() +
                    ".png"
                  }
                />
                {country.name}
              </div>
            ))}
          </div>
          <button
            className="edit_top_donators_button"
            onClick={this.handleSubmit}
          >
            Submit
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
};

export default connect(mapStateToProps, { setEditMonthWinner })(
  EditTopDonators
);
