import React, { Component } from "react";
import "./paypal.css";
import { connect } from "react-redux";
import { setUsers } from "../../actions/action";
import { db } from "../../config/fbConfig";
import cancel_icon from "../../img/cancel_icon.png";

class PaypalAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      client_id: "",
      visiblePopup: false,
      dataId: ""
    };
  }

  componentDidMount() {
    this.getPaypalId();
  }

  getPaypalId = () => {
    db.collection("paypal-ID")
      .get()
      .then(snapshot => {
        snapshot.docs.map(item =>
          this.setState({
            client_id: item.data().client_id
          })
        );
        let IDs = "";
        snapshot.docs.map(doc => {
          IDs = doc.id;
          return doc.data();
        });
        this.setState({
          dataId: IDs
        });
      });
  };

  editIdPopup = () => {
    this.setState({
      visiblePopup: !this.state.visiblePopup
    });
  };

  handleChange = e => {
    this.setState({
      client_id: e.target.value
    });
  };

  updateClientId = () => {
    db.collection("paypal-ID")
      .doc(this.state.dataId)
      .update({
        client_id: this.state.client_id
      });
    this.setState({
      visiblePopup: false
    });
  };

  closePopup = () => {
    this.setState({
      visiblePopup: false
    });
  };

  render() {
    const { client_id } = this.state;
    return (
      <React.Fragment>
        <div className="adminPanel_paypal_table_body">
          <h2>Client ID:</h2>
          <p>{client_id}</p>
          <button onClick={this.editIdPopup}>Edit</button>
        </div>
        {this.state.visiblePopup ? (
          <div className="paypalPopup">
            <div className="paypalPopup_holder">
              <img
                src={cancel_icon}
                className="paypalPopup_close"
                onClick={this.closePopup}
              />
              <input
                onChange={this.handleChange}
                type="text"
                placeholder="Edit Id"
              />
              <button onClick={this.updateClientId}>Submit</button>
            </div>
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return state;
};

export default connect(mapStateToProps, { setUsers })(PaypalAdmin);
