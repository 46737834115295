import React, { Component } from "react";
import "./loginForm.css";
import { connect } from "react-redux";
import { signIn } from "../../actions/authAction";
import { Redirect } from "react-router-dom";

class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: ""
    };
  }

  getInputValue = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.signIn(this.state);
  };

  render() {
    const { authError, auth } = this.props;
    console.log(auth);
    return (
      <div className="loginForm_wrapper">
        <form onSubmit={this.handleSubmit}>
          <div className="loginForm_row">
            <label>Email:</label>
            <input type="email" id="email" onChange={this.getInputValue} />
          </div>
          <div className="loginForm_row">
            <label>Password:</label>
            <input
              type="password"
              id="password"
              onChange={this.getInputValue}
            />
          </div>
          <div className="loginForm_btn">
            <button>Login</button>
          </div>
          <div className="loginFailded">
            {authError ? <p>{authError}</p> : null}
            {auth.uid ? <Redirect to="/adminPanel" /> : null}
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth
  };
};

export default connect(
  mapStateToProps,
  { signIn }
)(Form);
