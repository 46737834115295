import React, { Component } from "react";
import "./notifications.css";
import CloseIcon from "../../img/cancel_icon.png";

import level1 from "../../img/level1.png";
import level2 from "../../img/level2.png";
import level3 from "../../img/level3.png";

import { db } from "../../config/fbConfig";
import { connect } from "react-redux";
import {
  getRealTimeUsers,
  setDisplayUser,
  setNotificationUsers,
  setLastUser,
  setRemovedNotifications,
  setFilteredNotifications,
  setUsers,
  setPendingUsers
} from "../../actions/action";
import { createDonator } from "../../actions/formAction";
import moment from "moment";

import {
  setNotifications,
  getNotifications,
  deleteNotifications,
  getDeletedNotifications
} from "../../actions/authAction";

var startIndex = 0;
var endIndex = 3;

class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lastUser: "",
      usersAnimation: false,
      animationStatus: "",
      isModified: false
    };
  }

  componentDidMount() {
    console.log(
      moment()
        .add(75, "minutes")
        .format()
    );
    this.realTimeFirebase();
    this.getUsersFromFireBase();
    this.getPendingDonators();
    setInterval(() => {
      this.getUsersFromFireBase();
      this.getPendingDonators();
      let a = this.props.getUsers;
      let b = this.props.getPendingUsers;
      let date = moment().format();
      if (b != null) {
        b.map(user => {
          if (
            a.map(d => d.email.toString()) !== user.email.toString() &&
            user.date <= date
          ) {
            console.log("PROSLO");
            this.props.createDonator(user);
            this.deletePendingList(user);
          } else {
            console.log("NIJE");
          }
        });
      }
    }, 5000);
    clearInterval();
  }

  getUsersFromFireBase = () => {
    db.collection("donators")
      .orderBy("date", "desc")
      .get()
      .then(querySnapshot => {
        let IDs = [];
        let data = querySnapshot.docs.map(doc => {
          IDs.push(doc.id);
          return doc.data();
        });

        let dataWithID = data.map((item, index) => {
          item.id = IDs[index];

          return item;
        });

        this.props.setUsers(dataWithID);
        this.setState({
          users: dataWithID
        });
      });
  };

  getPendingDonators = () => {
    db.collection("pending-donators")
      .orderBy("date", "desc")
      .get()
      .then(querySnapshot => {
        let IDs = [];
        let data = querySnapshot.docs.map(doc => {
          IDs.push(doc.id);
          return doc.data();
        });

        let dataWithID = data.map((item, index) => {
          item.id = IDs[index];

          return item;
        });

        this.props.setPendingUsers(dataWithID);
      });
  };

  deletePendingList = users => {
    let id = users.id;
    db.collection("pending-donators")
      .doc(id)
      .delete();
  };

  setLevel = user => {
    if (user.donation <= 24) {
      return level3;
    } else if (user.donation >= 25 && user.donation <= 100) {
      return level2;
    } else {
      return level1;
    }
  };

  formatDate = date => {
    // 2019-09-12T11:43:16+02:00
    let year = date.substring(0, 4);
    let month = date.substring(5, 7);
    let day = date.substring(8, 10);
    let hour = date.substring(11, 13);
    let minute = date.substring(14, 16);
    let second = date.substring(17, 19);

    return year + month + day + hour + minute + second;
  };

  getItemFromLocalStorage = () => {
    let users_local = JSON.parse(localStorage.getItem("removed"));
    let for_delete =
      users_local !== null
        ? this.props.getRemovedNotifications.concat(users_local)
        : this.props.getRemovedNotifications;

    const unique = Array.from(new Set(for_delete.map(a => a.email))).map(
      email => {
        return for_delete.find(a => a.email === email);
      }
    );

    deleteNotifications(unique);
  };

  deleteNotification = (user, index, array) => {
    const currentNots = array.filter(u => u.username !== user.username);

    endIndex--;

    let removedUsers = this.props.getRemovedNotifications;
    removedUsers.push(user);
    this.props.setRemovedNotifications(removedUsers);

    this.getItemFromLocalStorage();

    setNotifications(currentNots);
    this.setState({
      usersAnimation: false,
      animationStatus: false
    });

    let a = this.props.getFilteredNotifications;
    let b = this.props.getLastUser;

    let c = a.map(i => i.email);
    let d = b.map(i => i.email);

    if (c.toString().indexOf(d.toString()) !== -1 && endIndex !== 4) {
      array.splice(index + 1, 1);
    } else {
      array.splice(index, 1);
    }

    console.log(startIndex, endIndex);
  };

  deleteSingleNotification = user => {
    let removedUsers = this.props.getRemovedNotifications;
    removedUsers.push(user);
    this.props.setRemovedNotifications(removedUsers);

    this.getItemFromLocalStorage();

    this.props.setNotificationUsers(false);
    this.props.setLastUser(this.props.getFilteredNotifications.slice(0, 1));
    this.setState({
      lastUser: false
    });

    console.log(startIndex, endIndex);
  };

  renderNotfications = (a, b) => {
    let arr = a;
    let arr2 = b;
    let arr3 = [];

    arr3 = arr.filter(o => !arr2.find(o2 => o.email === o2.email));
    return arr3;
  };

  renderUsers = (addedUsers, users) => {
    let allAddedUsers = addedUsers
      .concat(users)
      .sort(
        (a, b) =>
          parseInt(this.formatDate(b.date)) - parseInt(this.formatDate(a.date))
      )
      .slice(0, 50);
    return allAddedUsers;
  };

  realTimeFirebase = () => {
    db.collection("donators").onSnapshot(snapshot => {
      let changes = snapshot.docChanges();
      let users = [];
      let addedUsers = this.props.realTimeUsers;
      changes.map(change => {
        if (change.type === "added") {
          users.push(change.doc.data());
        } else if (change.type === "modified" || change.type === "removed") {
          console.log("modified", startIndex, endIndex, this.props.getLastUser);
          // users.push(this.props.getLastUser[0]);
          // startIndex += 1;
          // endIndex += 1;
          this.setState({
            isModified: true
          });
        }
      });
      const newUser = users
        .sort(
          (a, b) =>
            parseInt(this.formatDate(b.date)) -
            parseInt(this.formatDate(a.date))
        )
        .slice(0, 1);

      this.props.setLastUser(newUser);

      this.props.setDisplayUser("none");

      const renderUsers = this.renderUsers(addedUsers, users);

      this.props.getRealTimeUsers(renderUsers);

      // get localStorage

      if (getDeletedNotifications() === null) {
        setNotifications(renderUsers);
      } else {
        const renderNotifications = this.renderNotfications(
          this.props.realTimeUsers,
          getDeletedNotifications()
        );
        setNotifications(renderNotifications);
      }

      this.props.setFilteredNotifications(getNotifications());

      let a = this.props.getFilteredNotifications;
      let b = this.props.getLastUser;

      let c = a.map(i => i.email);
      let d = b.map(i => i.email);

      if (c.toString().indexOf(d.toString()) !== -1) {
        console.log("last user false");
        this.props.setNotificationUsers(true);
        if (endIndex === 0 && this.state.lastUser === false) {
          endIndex = -1;
          this.setState({
            lastUser: true
          });
          endIndex++;
          console.log(
            "endIndex === 0",
            this.state.lastUser,
            startIndex,
            endIndex
          );
        } else if (startIndex === -1 && endIndex === 4) {
          endIndex = 4;
        } else if (endIndex < 4 && this.state.lastUser !== false) {
          endIndex++;
          console.log(
            "endIndex < 4 && this.state.lastUser !== false",
            this.state.lastUser,
            startIndex,
            endIndex
          );
        } else if (endIndex === 5 && this.state.isModified === false) {
          startIndex = 0;
          endIndex = 4;
        } else if (startIndex === 0 && endIndex === 4) {
          console.log("startIndex === 0 && endIndex === 4");
        }
      } else {
        console.log("last user true");
        this.props.setNotificationUsers(false);
        startIndex = -1;
        endIndex = 4;
        this.setState({
          usersAnimation: true,
          lastUser: true
        });
      }

      console.log(
        "this.state.lastUser, startIndex, endIndex",
        this.state.lastUser,
        startIndex,
        endIndex
      );

      if (
        startIndex === 0 &&
        endIndex >= 4 &&
        this.state.animationStatus !== false &&
        this.state.isModified === false
      ) {
        this.setState({
          usersAnimation: true
        });
      }

      if (
        startIndex === -1 &&
        endIndex === 4 &&
        this.props.getNotificationUsers === true
      ) {
        startIndex += 1;
      }

      setTimeout(() => {
        this.props.setDisplayUser("flex");
      }, 1);
    });
  };

  title_img = user => {
    if (user.donation <= 24) {
      return "Rich Level 1";
    } else if (user.donation >= 25 && user.donation <= 100) {
      return "Rich Level 2";
    } else {
      return "Rich Level 3";
    }
  };

  render() {
    const {
      getLastUser,
      getDisplayUser,
      getFilteredNotifications,
      getNotificationUsers
    } = this.props;
    return (
      <div className="notifications_wrapper">
        <div className="notification">
          <ul className="notification_ul">
            {getNotificationUsers
              ? getLastUser.map((item, index) => (
                  <li
                    className={
                      this.state.isModified
                        ? "notification_li"
                        : "notification_li last_user_animation"
                    }
                    key={index}
                    style={{ display: `${getDisplayUser}` }}
                  >
                    <div
                      className="notification_li_img"
                      style={{ backgroundImage: `url(${item.country[0]})` }}
                      title={item.country[1]}
                    ></div>
                    <div className="notification_li_box">
                      <p className="notifi_title">
                        {item.username}
                        <img
                          src={this.setLevel(item)}
                          alt="kruna"
                          title={this.title_img(item)}
                        />
                      </p>
                      {item.message.length > 55 ? (
                        <p className="notifi_text">
                          {item.message.substring(0, 55)}
                          <span title={item.message}>...</span>
                        </p>
                      ) : (
                        <p className="notifi_text">{item.message}</p>
                      )}
                      <p
                        className="notifi_price"
                        title="Recent Donation"
                      >{`${item.donation}$`}</p>
                    </div>
                    <img
                      src={CloseIcon}
                      className="cancel_icon"
                      alt="cancel_img"
                      onClick={() =>
                        this.deleteSingleNotification(item, index, getLastUser)
                      }
                    />
                  </li>
                ))
              : null}
            {getFilteredNotifications
              .slice(startIndex + 1, endIndex + 1)
              .map((item, index) => (
                <li
                  key={index}
                  className={
                    "notification_li" +
                    `${this.state.usersAnimation === true ? " animation" : ""}`
                  }
                >
                  <div
                    className="notification_li_img"
                    style={{ backgroundImage: `url(${item.country[0]})` }}
                    title={item.country[1]}
                  ></div>
                  <div className="notification_li_box">
                    <p className="notifi_title">
                      {item.username}
                      <img
                        src={this.setLevel(item)}
                        alt="kruna"
                        title={this.title_img(item)}
                      />
                    </p>
                    {item.message.length > 55 ? (
                      <p className="notifi_text">
                        {item.message.substring(0, 55)}
                        <span title={item.message}>...</span>
                      </p>
                    ) : (
                      <p className="notifi_text">{item.message}</p>
                    )}
                    <p
                      className="notifi_price"
                      title="Recent Donation"
                    >{`${item.donation}$`}</p>
                  </div>
                  <img
                    src={CloseIcon}
                    className="cancel_icon"
                    alt="cancel_icon"
                    onClick={() =>
                      this.deleteNotification(
                        item,
                        index,
                        getFilteredNotifications
                      )
                    }
                  />
                </li>
              ))}
          </ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
};

export default connect(mapStateToProps, {
  getRealTimeUsers,
  setDisplayUser,
  setNotificationUsers,
  setLastUser,
  setRemovedNotifications,
  setFilteredNotifications,
  setUsers,
  setPendingUsers,
  createDonator
})(Notifications);
