import React, { Component } from "react";
import "./topDonators.css";
import moment from "moment";
import { connect } from "react-redux";
import { db } from "../../config/fbConfig";
import {
  setStatusEnvelope,
  setVisibleTopLists,
  setUsers,
  setTodayUsers,
  setMonthUsers,
  setEditTopDonators
} from "../../actions/action";
import EditTopDontors from "./EditTopDontors";

const languages = {
  en: require("../../languages/en.json"),
  fr: require("../../languages/fr.json"),
  es: require("../../languages/es.json"),
  ru: require("../../languages/ru.json"),
  cn: require("../../languages/cn.json")
};

// Get current day and current mounth
const todayDate = moment()
  .format()
  .substring(0, 10);
const month = moment()
  .format()
  .substring(0, 7);

class TopDonators extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listActive: "1",
      updatedUser: [],
      users: []
    };
  }

  componentWillUnmount() {
    this.props.setEditTopDonators(false);
  }

  componentDidMount() {
    // Call func get all users for firebase
    this.getUsersFromFireBase();
  }

  getUsersFromFireBase = () => {
    // Get all users for firebase
    db.collection("donators").onSnapshot(querySnapshot => {
      let IDs = [];
      let data = querySnapshot.docs.map(doc => {
        IDs.push(doc.id);
        return doc.data();
      });

      let dataWithID = data.map((item, index) => {
        item.id = IDs[index];

        return item;
      });

      // set all users
      this.props.setUsers(dataWithID);

      // set today users
      const currentDay = this.props.getUsers.filter(
        user => user.date.substring(0, 10) === todayDate
      );
      this.props.setTodayUsers(currentDay);

      // set month users
      const currentMonth = this.props.getUsers.filter(
        user => user.date.substring(0, 7) === month
      );
      this.props.setMonthUsers(currentMonth);
    });
  };

  openEditPopup = user => {
    this.setState({
      updatedUser: user
    });
    if (this.props.editPopUpStatus === false) {
      this.props.setEditTopDonators(true);
    } else {
      this.props.setEditTopDonators(false);
    }
  };

  changeList = e => {
    // Set active list and active button
    this.setState({
      listActive: [e.target.id].toString()
    });
  };

  grabStateFromChild = userForEdit => {
    let newUsers = [];
    this.state.users.map(user => {
      if (user.id === userForEdit.id) {
        user = userForEdit;
      }
      return newUsers.push(user);
    });

    this.setState({
      users: newUsers
    });
  };

  render() {
    const { getUsers, getTodayUsers, getMonthUsers } = this.props;

    const { listActive } = this.state;

    let local = localStorage.getItem("activeLang");

    return (
      <div className="topDonators_wrapper">
        {languages[local].top_list_page.map((item, index) => (
          <div key={index} className="topDonators_wrapper_holder">
            <div className="topDonators_page_buttons_wrapper">
              <button
                id="1"
                onClick={this.changeList}
                className={
                  listActive === "1" ? "topDonators_page_button_active" : ""
                }
              >
                {item.topToday}
              </button>
              <button
                id="2"
                onClick={this.changeList}
                className={
                  listActive === "2" ? "topDonators_page_button_active" : ""
                }
              >
                {item.topMonth}
              </button>
              <button
                id="3"
                onClick={this.changeList}
                className={
                  listActive === "3" ? "topDonators_page_button_active" : ""
                }
              >
                {item.topOfAllTime}
              </button>
            </div>
            {this.props.getEditTopDonators ? (
              <EditTopDontors
                user={this.state.updatedUser}
                grabStateFromChild={this.grabStateFromChild}
              />
            ) : null}
            <div className="">
              <div
                style={
                  listActive === "1"
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <h2 className="topDonator_naslov">{item.topToday}</h2>
                <ul className="topDonators_page_ul">
                  {getTodayUsers.length ? (
                    getTodayUsers
                      .sort(
                        (a, b) =>
                          parseFloat(b.donation) - parseFloat(a.donation)
                      )
                      .slice(0, 10)
                      .map((user, index) => {
                        return (
                          <div
                            key={user.id}
                            className="topDonators_page_ul_item"
                          >
                            <div className="topDonators_page_ul_item_content">
                              <div className="topDonators_page_ul_item_content_text">
                                <div className="topDonators_page_ul_item_content_text_number">
                                  <span>{`#${index + 1}`}</span>
                                </div>
                                <div className="topDonators_page_ul_item_content_text_username">
                                  {user.username}
                                </div>
                                <div>{`$${user.donation}`}</div>
                                <div className="topDonators_page_ul_item_content_text_icon">
                                  {user.message}
                                </div>
                                <div className="topDonators_page_ul_item_content_text_image_holder">
                                  <img
                                    src={user.country[0]}
                                    alt="country"
                                    title={user.country[1]}
                                  />
                                </div>
                              </div>
                              <button
                                className="edit_top_donator"
                                onClick={() => this.openEditPopup(user)}
                              >
                                Edit
                              </button>
                            </div>
                          </div>
                        );
                      })
                  ) : (
                    <p className="nohaveDonation">{item.noDonation}</p>
                  )}
                </ul>
              </div>
              <div
                style={
                  listActive === "2"
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <h2 className="topDonator_naslov">{item.topMonth}</h2>
                <ul>
                  {getMonthUsers.length ? (
                    getMonthUsers
                      .sort(
                        (a, b) =>
                          parseFloat(b.donation) - parseFloat(a.donation)
                      )
                      .slice(0, 10)
                      .map((user, index) => {
                        return (
                          <div
                            key={user.id}
                            className="topDonators_page_ul_item"
                          >
                            <div className="topDonators_page_ul_item_content">
                              <div className="topDonators_page_ul_item_content_text">
                                <div className="topDonators_page_ul_item_content_text_number">
                                  <span>{`#${index + 1}`}</span>
                                </div>
                                <div className="topDonators_page_ul_item_content_text_username">
                                  {user.username}
                                </div>
                                <div>{`$${user.donation}`}</div>
                                <div className="topDonators_page_ul_item_content_text_icon">
                                  {user.message}
                                </div>
                                <div className="topDonators_page_ul_item_content_text_image_holder">
                                  <img
                                    src={user.country[0]}
                                    alt="country"
                                    title={user.country[1]}
                                  />
                                </div>
                              </div>
                              <button
                                className="edit_top_donator"
                                onClick={() => this.openEditPopup(user)}
                              >
                                Edit
                              </button>
                            </div>
                          </div>
                        );
                      })
                  ) : (
                    <p className="nohaveDonation">{item.noDonation}</p>
                  )}
                </ul>
              </div>
              <div
                style={
                  listActive === "3"
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <h2 className="topDonator_naslov">{item.topOfAllTime}</h2>
                <ul>
                  {getUsers.length ? (
                    getUsers
                      .sort(
                        (a, b) =>
                          parseFloat(b.donation) - parseFloat(a.donation)
                      )
                      .slice(0, 10)
                      .map((user, index) => {
                        return (
                          <div
                            key={user.id}
                            className="topDonators_page_ul_item"
                          >
                            <div className="topDonators_page_ul_item_content">
                              <div className="topDonators_page_ul_item_content_text">
                                <div className="topDonators_page_ul_item_content_text_number">
                                  <span>{`#${index + 1}`}</span>
                                </div>
                                <div className="topDonators_page_ul_item_content_text_username">
                                  {user.username}
                                </div>
                                <div>{`$${user.donation}`}</div>
                                <div className="topDonators_page_ul_item_content_text_icon">
                                  {user.message}
                                </div>
                                <div className="topDonators_page_ul_item_content_text_image_holder">
                                  <img
                                    src={user.country[0]}
                                    alt="country"
                                    title={user.country[1]}
                                  />
                                </div>
                              </div>
                              <button
                                className="edit_top_donator"
                                onClick={() => this.openEditPopup(user)}
                              >
                                Edit
                              </button>
                            </div>
                          </div>
                        );
                      })
                  ) : (
                    <p className="nohaveDonation">{item.noDon}</p>
                  )}
                </ul>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
};

export default connect(mapStateToProps, {
  setStatusEnvelope,
  setVisibleTopLists,
  setUsers,
  setTodayUsers,
  setMonthUsers,
  setEditTopDonators
})(TopDonators);
