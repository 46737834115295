import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

// Initialize Firebase
var firebaseConfig = {
  apiKey: "AIzaSyCaOC1Zwgo-CaLEwCYwWc48IkDGy2HQPbk",
  authDomain: "rich-only-club.firebaseapp.com",
  databaseURL: "https://rich-only-club.firebaseio.com",
  projectId: "rich-only-club",
  storageBucket: "",
  messagingSenderId: "66247257262",
  appId: "1:66247257262:web:974cd665c181a861"

  // type: "service_account",
  // projectId: "richonlyclubdev",
  // private_key_id: "8e7a15e971829d0ec5916530026d4bc4e1f60d32",
  // private_key:
  //   "AIzaSyBVi-cL5eDeLS9lcPLzzgM0wE9cbQfUVbc\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQCQDIumVeotnyR+\n348E8ZQuRuXWb1qNrRKb9mI7tXRpJjO0lL1Qb5h/wbRF66zt06Ffk3CjYOV5rvFh\n9hyaVcLl6Sr6/LT4quuODeUV72ZbsIjaswrBjvMb6NJxgLCRydtJbPdmDFk0Exe1\nu4fk/UVltVeiD1sZlzb1ytwe04KnO0mKP7FjEj0G85pm+1YD/MFbm9DkPkmJVYKq\nHgwTkSKKZ+W41DR5CEb6hifJq6LH3L5J8uBDDvoZ7ZkZmH4oeqNoQNCTuqtDwCaq\niBWOZxVBQNdqxefMqzPMGSbPceZnmRBIw3KthsQGZe+iiN08ngVfMRl7RgykBjZ4\n0e/1rFGtAgMBAAECggEAArSpnts4RdkaZ2OtnQsonGHqiUeYLNcWZWWQXMzAvgTg\nrcCpeaxO8f9icnnFysXR3yi20erNBKcbk5p62Q9JVOY3T3WVtKIJQKbUKcBQbOJ4\n/5qjVhVoTZrm8QjYyDRBcTShxQHwXwEIzfoSyGNVfV6Voc6mCGoFt0IzNkUEzD1S\noKQM1JVk7wZKKjpDGL+rGgafxmyez91hOv/0O3QGzmFobBW5OuOwykxrn/nZTmcb\nB3ROxOmk4v/G+9sW+O/PjpNSKlfuD4DQNQVui5eBAS8A+Qotm5IWuiLW+n9Z5XDd\n7ScHD9onBuTK7J2n1UAZ40KRBQ2mkQ/rDxTZwWbc5QKBgQDI0FQyPefC6rkpKw3M\ni7ZSmD2Vq76WdycQL72VLIjKkb8d9zeq/0qbF7nuYInYjx/7UjhjsxgjUv/n0qbx\nk7Xcf6Y2SzvIDrfS+nh+90f4C9JPw7rt50MICYgvkhO5ln+zLG0jJiSRo4sqbYgI\nsdXjSrJz58z/hR58qwjLBqaW3wKBgQC3orIAXXoEu7Ab3M0HE3aAth2Yywdy6wt9\na8f7FLrtnlwVbmWR393DmIMJtIX367TE7kzYOjLGAaSzpBhD/0ovbsOf98l2JUDz\n2iTj8mf7//vpLVqy02CfVu67fBqBgAslX4SkMai4gusHdi6yP4N6Li/6thAcJ1Ss\nFBhpTORk8wKBgHyxD3bbBav8f9jWSQ03u5Bxw9vJ9uALQJBGdJvKOW4/LcL3nPNq\n7fJ7uxIPk8iKprsxhJ4zuMtGYGycWtkqdQRjptuTv50rtm0k5elAtsmI3aB10v6i\nWPcGpC+VrvXQQ58H+QSYTKC6QlGoDY4Ijt1Bpqldx+bpy9JxiVY33P1VAoGAGoeK\np7rZce2bl6rig+8v45Br3jMWtGd4lXoIviFUkBecvgvg6J4q76MM604FPLf0ua6j\ns2lVBH4gSUOfrdMn6IpcALRPip3/MDuHsNbIZwOoZRuESul2wA5TFwrq2WKiOZf/\nFamG8oEXa1CLlnzOhcw+aj37PdYAyYttq2jMZjkCgYBg5jx/Wq/1LOouQ0l2Ak2c\n6tGbSmq+9ygATSeFXuJbp1rIoSS8TY8Qr0EUlZa2waTHi5FA3LRW9177wSnNtXPG\nOFkXPlNcLnAZgohclqI29oCo02l+hCACVkjFbilo5Y6TU8w9U5Yxjmv6NDoN+7R/\nWyHyvCf57HryS02OCfyTBw==\n-----END PRIVATE KEY-----\n",
  // client_email: "richonlyclubdev@appspot.gserviceaccount.com",
  // client_id: "113624791922936427963",
  // auth_uri: "https://accounts.google.com/o/oauth2/auth",
  // token_uri: "https://oauth2.googleapis.com/token",
  // auth_provider_x509_cert_url: "https://www.googleapis.com/oauth2/v1/certs",
  // client_x509_cert_url:
  //   "https://www.googleapis.com/robot/v1/metadata/x509/richonlyclubdev%40appspot.gserviceaccount.com"

  // projectId: "rich-only-club",

  // apiKey: "AIzaSyDLm_ZgQAwbw2H0TyCElwzcVKynD_WpZ6U",
  // authDomain: "reachonlyclub.firebaseapp.com",
  // databaseURL: "https://reachonlyclub.firebaseio.com",
  // projectId: "reachonlyclub",
  // storageBucket: "",
  // messagingSenderId: "58450931547",
  // appId: "1:58450931547:web:2e80513393c5928a0d747e"
};
var firebaseApp = firebase.initializeApp(firebaseConfig);

export const db = firebaseApp.firestore();

export default firebase;
