import React, { Component } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

// Import Components
import HomePage from "./components/HomePage/HomePage";
import TermsPage from "./components/TermsPage/TermsPage";
import LoginForm from "./components/LoginForm/LoginForm";
import AdminPanel from "./adminPanel/adminPanel";

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div className="app">
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route path="/terms" component={TermsPage} />
            <Route path="/loginForm" component={LoginForm} />
            <Route path="/adminPanel" component={AdminPanel} />
          </Switch>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
