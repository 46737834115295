export const createPendingDonator = donator => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
      const firestore = getFirestore();
      firestore
        .collection("pending-donators")
        .add({
          ...donator
        })
        .then(() => {
          dispatch({ type: "CREATE_PENDING_DONATOR", donator });
        })
        .catch(err => {
          dispatch({ type: "CREATE_PENDING_DONATOR", err });
        });
    };
  };
  