import React, { Component } from "react";
import "./homePage.css";
import Notifications from "../Notifications/Notifications";
import { connect } from "react-redux";
import {
  setVisiblePopUp,
  setVisibleTopLists,
  setErrorPopupStatus,
  getDonationMoney,
  setVisibleProtectPopup,
  setLanguage,
  setUsers,
  setMonthUsers
} from "../../actions/action";
import Form from "../Form/Form";
import ProtectPopup from "./ProtectPopup/ProtectPopup";
import TopLists from "../TopLists/TopLists";
import TopListError from "../TopLists/TopListError/TopListError";
import MiniImage from "../../img/joki-projekat.png";
import moment from "moment";
import { db } from "../../config/fbConfig";

import WinnerIcon from "../../img/winner_icon.png";
import level1 from "../../img/level1.png";
import level2 from "../../img/level2.png";
import level3 from "../../img/level3.png";

const languages = {
  en: require("../../languages/en.json"),
  fr: require("../../languages/fr.json"),
  es: require("../../languages/es.json"),
  ru: require("../../languages/ru.json"),
  cn: require("../../languages/cn.json")
};

const current_month = moment()
  .format()
  .substring(0, 7);
console.log(current_month, "current_month");

class HomePage extends Component {
  constructor(props) {
    super(props);
    let getItem = localStorage.getItem("activeLang");
    if (getItem === null) {
      localStorage.setItem("activeLang", "en");
    }
    this.state = {
      amount: "",
      inputError: false,
      protectPopupVisible: false,
      visibleLanguagePopup: false,
      allLanguages: [
        ["en", "https://flagpedia.net/data/flags/normal/gb.png"],
        ["fr", "https://flagpedia.net/data/flags/normal/fr.png"],
        ["es", "https://flagpedia.net/data/flags/normal/es.png"],
        ["ru", "https://flagpedia.net/data/flags/normal/ru.png"],
        ["cn", "https://flagpedia.net/data/flags/normal/cn.png"]
      ],
      activeLanguage: "en",
      rand_color: 0
    };
  }

  componentDidMount() {
    // Call func get all users for firebase
    this.getUsersFromFireBase();
  }

  getUsersFromFireBase = () => {
    // Get all users for firebase
    db.collection("donators").onSnapshot(querySnapshot => {
      let IDs = [];
      let data = querySnapshot.docs.map(doc => {
        IDs.push(doc.id);
        return doc.data();
      });

      let dataWithID = data.map((item, index) => {
        item.id = IDs[index];

        return item;
      });

      // set all users
      this.props.setUsers(dataWithID);

      const currentMonth = this.props.getUsers.filter(
        user => user.date.substring(0, 7) === current_month
      );
      this.props.setMonthUsers(currentMonth);
    });
  };

  handleChange = e => {
    this.setState({
      amount: e.target.value
    });
  };

  returnAmountToZero = amount => {
    this.setState({
      amount
    });
  };

  openForm = () => {
    if (this.state.amount !== "" && this.state.amount > 0) {
      this.props.setVisiblePopUp(true);
      this.props.getDonationMoney(this.state.amount);
      this.props.setVisibleProtectPopup(false);
    } else {
      this.setState({
        inputError: true
      });
    }
  };

  handleFocus = () => {
    this.setState({
      inputError: false
    });
  };

  openTopList = () => {
    if (localStorage.getItem("Success donation") === "RichOnlyClub") {
      this.props.setVisibleTopLists(true);
      this.props.setVisibleProtectPopup(false);
    } else {
      this.props.setErrorPopupStatus(true);
    }
  };

  openProtectPopup = () => {
    this.props.setVisibleProtectPopup(true);
  };

  languagePopup = () => {
    this.setState({
      visibleLanguagePopup: !this.state.visibleLanguagePopup
    });
  };

  setActiveLanguage = lang => {
    let target = lang[0];
    this.setState({
      activeLanguage: target,
      visibleLanguagePopup: false
    });
    localStorage.setItem("activeLang", target);
    this.props.setLanguage(target);
  };

  setLevel = user => {
    if (user.donation <= 24) {
      return level3;
    } else if (user.donation >= 25 && user.donation <= 100) {
      return level2;
    } else {
      return level1;
    }
  };

  title_img = user => {
    if (user.donation <= 24) {
      return "Rich level 1";
    } else if (user.donation >= 25 && user.donation <= 100) {
      return "Rich level 2";
    } else {
      return "Rich level 3";
    }
  };

  randomColor = () => {
    let color = Math.ceil(Math.random() * 360);
    this.setState({
      rand_color: color
    });
  };

  returnColor = () => {
    this.setState({
      rand_color: 360
    });
  };

  render() {
    let local = localStorage.getItem("activeLang");
    const {
      inputError,
      allLanguages,
      amount,
      visibleLanguagePopup
    } = this.state;
    return (
      <React.Fragment>
        {languages[local].home_page.map((item, index) => (
          <div className="homePage" key={index}>
            <div className="homePage_content_wrapper">
              <div className="logo_wrapper">
                <h1
                  onClick={this.randomColor}
                  onMouseLeave={this.returnColor}
                  className="richLogo"
                  style={{ filter: `hue-rotate(${this.state.rand_color}deg)` }}
                >
                  Rich Only Club
                </h1>
              </div>
              {this.props.errorPopupStatus ? <TopListError /> : null}
              <label className="homePage_label">
                <input
                  type="number"
                  className="homePage_input"
                  onChange={this.handleChange}
                  onFocus={this.handleFocus}
                  placeholder={inputError ? item.placeholder : item.amount}
                  value={amount}
                />
                <button className="homePage_btn" onClick={this.openForm}>
                  <span>$</span>
                </button>
              </label>
              <div className="terms_list">
                <button className="terms">
                  <a href="/terms" target="_blank">
                    {item.terms}
                  </a>
                </button>
                <button className="list" onClick={this.openTopList}>
                  {item.list}
                </button>
              </div>
            </div>
            {this.props.visiblePopUp ? (
              <Form
                amount={amount}
                returnAmountToZero={this.returnAmountToZero}
              />
            ) : null}
            {this.props.visibleTopLists ? <TopLists /> : null}
            <Notifications />
            <div className="language_section">
              <p className="language_title">{item.language}:</p>
              <div className="languagePopup">
                <p onClick={this.languagePopup}>{local}</p>
                {visibleLanguagePopup ? (
                  <div className="language_select_wrapper">
                    <div className="language_select_holder">
                      {allLanguages.map((lang, index) => (
                        <p
                          key={index}
                          onClick={() => this.setActiveLanguage(lang)}
                          className={
                            visibleLanguagePopup
                              ? "language_select language_select_active"
                              : "language_select"
                          }
                        >
                          {lang[0]}
                          <img src={lang[1]} alt="countryPic" />
                        </p>
                      ))}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="mini_image" onClick={this.openProtectPopup}>
              <img
                src={MiniImage}
                alt="mini_img"
                title="Global internet security"
              />
            </div>
            {this.props.visibleProtectPopup ? <ProtectPopup /> : null}
          </div>
        ))}
        {this.props.getMonthUsers.length
          ? this.props.getMonthUsers
              .sort((a, b) => parseFloat(b.donation) - parseFloat(a.donation))
              .slice(0, 1)
              .map((item, index) => (
                <div key={index} className="month_winner_wrapper_notifi">
                  <div className="month_winner_notifi_holder">
                    <div
                      className="notification_li_img"
                      style={{ backgroundImage: `url(${item.country[0]})` }}
                      alt="userCountry"
                      title={item.country[1]}
                    ></div>
                    <div className="notification_li_box">
                      <p className="notifi_title">
                        {item.username}
                        <img
                          src={this.setLevel(item)}
                          alt="kruna"
                          title={this.title_img(item)}
                        />
                        <img
                          src={WinnerIcon}
                          alt="winner"
                          title="Monthly Winner"
                        />
                      </p>
                      {item.message.length > 55 ? (
                        <p className="notifi_text">
                          {item.message.substring(0, 55)}
                          <span title={item.message}>...</span>
                        </p>
                      ) : (
                        <p className="notifi_text">{item.message}</p>
                      )}
                      <p
                        className="notifi_price"
                        title="Full Amount Monthly Winner Has Access To"
                      >{`${item.donation}$`}</p>
                    </div>
                  </div>
                </div>
              ))
          : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return state;
};

export default connect(mapStateToProps, {
  setVisiblePopUp,
  setVisibleTopLists,
  setErrorPopupStatus,
  getDonationMoney,
  setVisibleProtectPopup,
  setLanguage,
  setUsers,
  setMonthUsers
})(HomePage);
