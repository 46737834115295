const initState = {};

const pendingDonatorReducer = (state = initState, action) => {
  switch (action.type) {
    case "CREATE_PENDING_DONATOR":
      console.log("created pending donator", action.donator);
      return state;
    case "CREATE_PENDING_DONATOR_ERROR":
      console.log("created pending donator error", action.err);
      return state;
    default:
      return state;
  }
};

export default pendingDonatorReducer;
