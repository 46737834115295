import React from "react";
import "./pagination.css";
import PropTypes from "prop-types";
import _ from "lodash";

const Pagination = props => {
  const { itemsDonators, pageSize, currentPage, onPageChange } = props;
  const pageCount = Math.ceil(itemsDonators / pageSize);
  if (pageCount === 1) return null;

  const pages = _.range(1, pageCount + 1);

  return (
    <div className="pagination_wrapper">
      <ul>
        {pages.map(page => (
          <li key={page} className={page === currentPage ? "page_active" : ""}>
            <div onClick={() => onPageChange(page)}>{page}</div>
          </li>
        ))}
      </ul>
    </div>
  );
};

Pagination.propTypes = {
  itemsDonators: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired
};

export default Pagination;
