import authReducer from "./authReducer";
import reducerForm from "./reducerForm";
import cronReducer from "./cronReducer";
import { combineReducers } from "redux";
import { firestoreReducer } from "redux-firestore";
import { firebaseReducer } from "react-redux-firebase";

import {
  SET_VISIBLE_POPUP,
  SET_AUTH_FORM,
  GET_DONATION_MONEY,
  SET_STATUS_TOP_LISTS,
  SET_STATUS_ENVELOPE,
  SET_USERS,
  SET_TODAY_USERS,
  SET_MONTH_USERS,
  GET_REAL_TIME_USERS,
  SET_EDIT_POPUP_STATUS,
  SET_DISPLAY_USER,
  SET_NOTIFICATION_USERS,
  SET_LAST_USER,
  SET_REMOVED_NOTIFICATIONS,
  SET_FILTERED_NOTIFICATIONS,
  SET_ERROR_POPUP_STATUS,
  SET_VISIBLE_PROTECT_POPUP,
  SET_LANGUAGE,
  SET_EDIT_TOP_DONATORS,
  SET_EDIT_MONTH_WINNER,
  SET_PENDING_USERS
} from "../actions/action";

function visiblePopUp(state = false, action) {
  switch (action.type) {
    case SET_VISIBLE_POPUP:
      return action.visible;
    default:
      return state;
  }
}

function formData(state = [], action) {
  switch (action.type) {
    case SET_AUTH_FORM:
      return action.data;
    default:
      return state;
  }
}

function donationMoney(state = "", action) {
  switch (action.type) {
    case GET_DONATION_MONEY:
      return action.money;
    default:
      return state;
  }
}

function getUsers(state = [], action) {
  switch (action.type) {
    case SET_USERS:
      return action.users;
    default:
      return state;
  }
}

function getTodayUsers(state = [], action) {
  switch (action.type) {
    case SET_TODAY_USERS:
      return action.users;
    default:
      return state;
  }
}

function getMonthUsers(state = [], action) {
  switch (action.type) {
    case SET_MONTH_USERS:
      return action.users;
    default:
      return state;
  }
}

function visibleTopLists(state = false, action) {
  switch (action.type) {
    case SET_STATUS_TOP_LISTS:
      return action.status;
    default:
      return state;
  }
}

function statusEnvelope(state = false, action) {
  switch (action.type) {
    case SET_STATUS_ENVELOPE:
      return action.envelope;
    default:
      return state;
  }
}

function realTimeUsers(state = [], action) {
  switch (action.type) {
    case GET_REAL_TIME_USERS:
      return action.users;
    default:
      return state;
  }
}

function editPopUpStatus(state = false, action) {
  switch (action.type) {
    case SET_EDIT_POPUP_STATUS:
      return action.status;
    default:
      return state;
  }
}

function getDisplayUser(state = "", action) {
  switch (action.type) {
    case SET_DISPLAY_USER:
      return action.status;
    default:
      return state;
  }
}

function getNotificationUsers(state = "", action) {
  switch (action.type) {
    case SET_NOTIFICATION_USERS:
      return action.users;
    default:
      return state;
  }
}

function getLastUser(state = [], action) {
  switch (action.type) {
    case SET_LAST_USER:
      return action.user;
    default:
      return state;
  }
}

function getRemovedNotifications(state = [], action) {
  switch (action.type) {
    case SET_REMOVED_NOTIFICATIONS:
      return action.item;
    default:
      return state;
  }
}
function errorPopupStatus(state = false, action) {
  switch (action.type) {
    case SET_ERROR_POPUP_STATUS:
      return action.status;
    default:
      return state;
  }
}

function getFilteredNotifications(state = [], action) {
  switch (action.type) {
    case SET_FILTERED_NOTIFICATIONS:
      return action.item;
    default:
      return state;
  }
}

function visibleProtectPopup(state = false, action) {
  switch (action.type) {
    case SET_VISIBLE_PROTECT_POPUP:
      return action.visible;
    default:
      return state;
  }
}

function language(state = "en", action) {
  switch (action.type) {
    case SET_LANGUAGE:
      return action.language;
    default:
      return state;
  }
}

function getEditTopDonators(state = false, action) {
  switch (action.type) {
    case SET_EDIT_TOP_DONATORS:
      return action.status;
    default:
      return state;
  }
}

function getEditMonthWinner(state = false, action) {
  switch (action.type) {
    case SET_EDIT_MONTH_WINNER:
      return action.status;
    default:
      return state;
  }
}

function getPendingUsers(state = null, action) {
  switch (action.type) {
    case SET_PENDING_USERS:
      return action.user;
    default:
      return state;
  }
}

const rootReducer = combineReducers({
  auth: authReducer,
  reducerForm,
  cronReducer,
  visiblePopUp,
  formData,
  donationMoney,
  firestore: firestoreReducer,
  firebase: firebaseReducer,
  getUsers,
  getTodayUsers,
  getMonthUsers,
  visibleTopLists,
  statusEnvelope,
  realTimeUsers,
  editPopUpStatus,
  getDisplayUser,
  getNotificationUsers,
  getLastUser,
  getRemovedNotifications,
  getFilteredNotifications,
  errorPopupStatus,
  visibleProtectPopup,
  language,
  getEditTopDonators,
  getEditMonthWinner,
  getPendingUsers
});

export default rootReducer;
